import { IHeadingBlock } from "./interface";
import styles from "./styles";

export const H2: React.FC<IHeadingBlock> = ({ text, icon, isCentered = false }: IHeadingBlock) => {
  return (
    <styles.H2Wrapper isCentered={isCentered} withIcon={Boolean(icon)}>
      {icon || null}
      {text}
    </styles.H2Wrapper>
  );
};
