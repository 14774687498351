import isEmpty from "lodash/isEmpty";

import FungibleTokenContract from "services/contracts/FungibleToken";
import { TWO } from "shared/constants";
import { getTokenColor } from "shared/helpers";
import { ITokenPrice } from "shared/interfaces";
import { BigNumber, formatUnits } from "shared/utils/calculations";

export const setDoughnutData = (
  whiteListedTokens: { [key: string]: string },
  tokens: { [key: string]: FungibleTokenContract },
  prices: { [key: string]: ITokenPrice }
) => {
  if (isEmpty(tokens) || isEmpty(prices)) return;

  const tokenIds = Object.keys(whiteListedTokens);
  const data = tokenIds.map((tokenId) =>
    BigNumber(formatUnits(whiteListedTokens[tokenId], tokens[tokenId].metadata.decimals))
      .mul(BigNumber(prices[tokenId].price))
      .toFixed(TWO)
  );
  const colors = tokenIds.map((tokenId) => getTokenColor(tokenId).circle);

  return { data, colors };
};
