import styled from "styled-components";

const PseudoStakingPoolWrapper = styled.div`
  div {
    div {
      &:nth-child(1) {
        height: 10.95rem;
      }

      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4) {
        height: 5.075rem;
      }

      &:nth-child(5) {
        height: 5.125rem;
        ${({ theme }) => theme.mediaWidth("upToSmall", `height: 9.95rem;`)}
      }
    }
  }
`;

export default {
  PseudoStakingPoolWrapper,
};
