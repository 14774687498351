import styled from "styled-components";

import { ReactComponent as SimCornerIcon } from "assets/images/sim-components/corner-sim.svg";
import { ReactComponent as CornerIcon } from "assets/images/sim-components/corner.svg";

import { ECornerPosition } from "./enum";
import { getSimCornerStyles } from "./helper";

const Limiters = styled.div`
  &:before {
    content: "";
    top: 0;
    left: 0;
    z-index: 9;
    width: 100%;
    height: 1.5rem;
    position: fixed;
    background-color: ${({ theme }) => theme.colors.black};
  }

  &:after {
    content: "";
    left: 0;
    bottom: 0;
    z-index: 9;
    width: 100%;
    height: 1.5rem;
    position: fixed;
    background-color: ${({ theme }) => theme.colors.black};
  }
`;

const SimWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: -2;
  width: 100%;
  height: 100%;
  padding: 1.5rem;
  background-color: ${({ theme }) => theme.colors.black};
`;

const Sim = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.background};
`;

const SimCorner = styled(SimCornerIcon)`
  position: fixed;
  left: 1.5rem;
  bottom: 1.5rem;
  clip-path: polygon(-13% 0, 0% 100%, 87% 100%);
`;

const Corner = styled(CornerIcon)<{ position: ECornerPosition }>`
  position: fixed;
  clip-path: polygon(100% 0%, 100% 1.5rem, 1.5rem 1.5rem, 1.5rem 100%, 0% 100%, 0% 0%);
  ${({ position }) => getSimCornerStyles(position)};
`;

export default {
  Limiters,
  SimWrapper,
  Sim,
  SimCorner,
  Corner,
};
