import { v4 as uuid } from "uuid";

import { ITwoColumnListProps } from "./interface";
import styles from "./styles";

export default function TwoColumnList({ list }: ITwoColumnListProps) {
  return (
    <styles.TwoColumnListWrapper>
      {list.map((listItem) => (
        <styles.Element key={uuid()}>
          <div>{listItem.title}</div>
          <div>{listItem.value}</div>
        </styles.Element>
      ))}
    </styles.TwoColumnListWrapper>
  );
}
