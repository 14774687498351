import { createAsyncThunk } from "@reduxjs/toolkit";

import { IRPCProviderService } from "services/RPCProviderService";

import { loadPreStakingData } from "./loadPrestakingData";
import { RootState } from "../index";

export const updateState = createAsyncThunk<
  void,
  {
    provider: IRPCProviderService;
    contractId: string;
  },
  { state: RootState }
>("preStaking/updateApp", async ({ provider, contractId }, { dispatch }) => {
  try {
    dispatch(loadPreStakingData({ provider, contractId }));
  } catch (e) {
    console.error(`Error: while loading pre-staking data from contract \n ${e}`);
  }
});
