import poolInformationStyles from "shared/components/StakingPoolInformation/styles";

import styles from "./styles";

export const Preloading = () => {
  return (
    <styles.PseudoStakingPoolWrapper>
      <poolInformationStyles.StakingPoolInformationWrapper>
        <poolInformationStyles.TotalReward />
        <poolInformationStyles.APR />
        <poolInformationStyles.Duration />
        <poolInformationStyles.TotalStakers />
        <poolInformationStyles.TotalStaked />
      </poolInformationStyles.StakingPoolInformationWrapper>
    </styles.PseudoStakingPoolWrapper>
  );
};
