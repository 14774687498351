import { DEFAULT_MODAL_WIDTH_IN_REM } from "shared/constants";
import { IModalProps } from "shared/interfaces";
import { backgroundLayoutVariants, modalVariants } from "shared/theme/animations/modals";

import styles from "./styles";

export default function ModalWrapper({
  children,
  closeModal,
  widthInRem = DEFAULT_MODAL_WIDTH_IN_REM,
  clearDefaultStyles = false,
}: IModalProps): JSX.Element {
  return (
    <styles.BackgroundLayout
      onClick={closeModal}
      variants={backgroundLayoutVariants}
      initial="initial"
      animate="animate"
      exit="exit"
    >
      <styles.CornerModalContainer>
        <styles.Modal
          onClick={(e) => e.stopPropagation()}
          variants={modalVariants}
          initial="initial"
          animate="animate"
          exit="exit"
          $widthInRem={widthInRem}
          $clearDefaultStyles={clearDefaultStyles}
        >
          <styles.ModalHolder>{children}</styles.ModalHolder>
        </styles.Modal>
        <styles.ModalCorner />
      </styles.CornerModalContainer>
    </styles.BackgroundLayout>
  );
}
