import Big from "big.js";

import { DAYS_A_YEAR, ONE_HUNDRED, ZERO_STRING } from "shared/constants";
import { removeTrailingZeros } from "shared/utils";

import { getUserFriendlyAmount } from "./getUserFriendlyAmount";

const BASE = 10;
Big.RM = Big.roundDown;
Big.DP = 30;

export const BigNumber = (value: string | number | Big | undefined | null) => {
  try {
    return Big(value || ZERO_STRING);
  } catch (error) {
    console.warn(`Error: ${error} \n\n while working with this value ${value} of such a type ${typeof value} `);
    return Big(ZERO_STRING);
  }
};

export const convertToDecimalsNumber = (value: string, decimals: number): string => {
  if (decimals === null || decimals === undefined) return value;
  const [wholePart, fracPart = ""] = value.split(".");
  const formatFraction = fracPart.padEnd(decimals, "0").slice(0, decimals);
  const unitedParts = `${wholePart}${formatFraction}`;
  const replaceLeadingZero = unitedParts.replace(/^0+/, "");
  const checkEmptyString = replaceLeadingZero.padStart(1, "0");
  return checkEmptyString;
};

export const formatUnits = (value: string | number | Big, decimals = 18, precision?: number): string => {
  try {
    if (!value) return ZERO_STRING;

    return removeTrailingZeros(BigNumber(value).div(BigNumber(BASE).pow(decimals)).toFixed(precision));
  } catch (error) {
    console.error(`Error Big formatUnits \n ${error}`);
    return ZERO_STRING;
  }
};

export const formatUnitsWithSpaces = (value: string | number | Big, decimals = 18, precision?: number): string => {
  try {
    if (!value) return ZERO_STRING;

    return getUserFriendlyAmount(BigNumber(value).div(BigNumber(BASE).pow(decimals)), precision);
  } catch (error) {
    console.error(`Error Big formatUnits \n ${error}`);
    return ZERO_STRING;
  }
};

export const calculateAPY = (
  sessionInterval: number,
  totalStakedFiat: number,
  totalRewardFiat: number,
  inputValue = ZERO_STRING
) => {
  const depAmount = Number(inputValue);
  if ((depAmount && depAmount > 0) || (totalStakedFiat && totalStakedFiat > 0)) {
    const rewardForYear = (totalRewardFiat / sessionInterval) * DAYS_A_YEAR;
    const staked = totalStakedFiat + depAmount;
    return getUserFriendlyAmount((rewardForYear / staked) * ONE_HUNDRED);
  }

  return 0;
};
