import { useState, useCallback, useMemo } from "react";

import { ReactComponent as TooltipIcon } from "assets/images/icons/tooltip.svg";
import { EDimensions } from "shared/constants";
import { useAppDispatch } from "shared/hooks/redux/useAppDispatch";
import { useAppSelector } from "shared/hooks/redux/useAppSelector";
import useWindowDimensions from "shared/hooks/useWindowDimensions";
import { EModals, selectModalState, showModal } from "store/slices/modals";

import { ITooltipProps } from "./interface";
import styles from "./styles";

export default function Tooltip({ title, icon = <TooltipIcon /> }: ITooltipProps) {
  const modalState = useAppSelector(selectModalState);
  const dispatch = useAppDispatch();
  const dimensions = useWindowDimensions();
  const isMobile = useMemo(() => dimensions === EDimensions.SMALL || dimensions === EDimensions.MEDIUM, [dimensions]);

  const [show, setShow] = useState<boolean>(false);
  const onMouseOver = useCallback(() => {
    if (isMobile) return;
    setShow(true);
  }, [isMobile]);

  const onMouseOut = useCallback(() => {
    if (isMobile) return;
    setShow(false);
  }, [isMobile]);

  const handleClick = useCallback(() => {
    if (isMobile) {
      dispatch(showModal({ modal: EModals.TOOLTIP_MODAL, props: { title } }));
    }
  }, [dispatch, isMobile, title]);

  const isTooltipModalOpen = modalState.modal === EModals.TOOLTIP_MODAL;

  return (
    <styles.Container onMouseOver={onMouseOver} onMouseOut={onMouseOut} onClick={handleClick}>
      {icon}
      {show && !isTooltipModalOpen && <styles.HoverContent show={show}>{title}</styles.HoverContent>}
    </styles.Container>
  );
}
