import styled from "styled-components";

import { hoverTransition } from "shared/theme/styles";

const StakeForm = styled.form`
  gap: 1.5rem;
  display: flex;
  flex-wrap: wrap;
  margin-top: 1.5rem;
  align-items: flex-start;

  ${({ theme }) => theme.mediaWidth("upToSmall", `gap: 2rem;`)}
`;

const StakeMainBlock = styled.div`
  width: 55%;

  ${({ theme }) => theme.mediaWidth("upToSmall", `width: 100%;`)}
`;

const StakePolicyBlock = styled.div`
  flex: 1;
  padding: 1rem;
  overflow: hidden;
  border-radius: 1rem;
  margin-top: 1.125rem;
  background-color: ${({ theme }) => theme.colors.greyBackground};

  ${({ theme }) => theme.mediaWidth("upToSmall", `width: 100%;`)}
`;

const Info = styled.div`
  gap: 0.5rem;
  display: flex;
`;

const Text = styled.div`
  flex: 1;

  p {
    font-weight: 400;
    line-height: 140%;
    font-size: 0.75rem;
    margin: 0 0 1.5rem 0;
    color: ${({ theme }) => theme.colors.greyDark};
  }
`;

const ButtonsWrapper = styled.div`
  bottom: 0;
  z-index: 9;
  width: 100%;
  position: sticky;
  padding-top: 1.75rem;
  background: ${({ theme }) => theme.colors.white};
`;

const InputWithError = styled.div`
  margin-bottom: 2.375rem;
`;

const InputContainer = styled.div`
  position: relative;

  input {
    padding-right: 9rem;
    padding-left: 2.5rem;
  }
`;

const InputAdditions = styled.div`
  top: 0;
  right: 0;
  height: 100%;
  display: flex;
  overflow: hidden;
  padding: 0 0.5rem;
  position: absolute;
  align-items: center;
  border-radius: 0.75rem;
`;

const TokenName = styled.div`
  font-size: 1rem;
  font-weight: 700;
  line-height: 120%;
  margin: 0 0 0 auto;
  color: ${({ theme }) => theme.colors.blue};
`;

const ButtonMax = styled.button`
  border: none;
  outline: none;
  cursor: pointer;
  height: 1.75rem;
  font-weight: 700;
  padding: 0 0.5rem;
  font-size: 0.666rem;
  border-radius: 0.25rem;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.blue};
  background-color: ${({ theme }) => theme.colors.buttonGrey};
  transition: ${hoverTransition};

  &:hover {
    background-color: ${({ theme }) => theme.colors.buttonGreyHover};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.buttonGreyDisabled};
  }
`;

const Separator = styled.div`
  width: 1px;
  height: 1rem;
  margin: 0 0.75rem;
  border-radius: 1px;
  background-color: ${({ theme }) => theme.colors.greyLight};
`;

const LabelBalance = styled.div`
  display: flex;
  padding: 0 0.5rem;
  margin-bottom: 0.5rem;
  justify-content: space-between;
`;

const Label = styled.div`
  font-weight: 500;
  line-height: 120%;
  font-size: 0.75rem;
  color: ${({ theme }) => theme.colors.greyLight};
`;

const Balance = styled.div`
  font-weight: 500;
  line-height: 120%;
  font-size: 0.75rem;
  color: ${({ theme }) => theme.colors.greyLight};

  span {
    margin-left: 0.5rem;
    color: ${({ theme }) => theme.colors.blue};
  }
`;

const IconToken = styled.img`
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  object-fit: contain;
`;

const IconWrapper = styled.div`
  top: 50%;
  left: 0.5rem;
  display: flex;
  width: 1.75rem;
  height: 1.75rem;
  overflow: hidden;
  position: absolute;
  align-items: center;
  justify-content: center;
  transform: translateY(-50%);
`;

export default {
  StakeForm,
  StakeMainBlock,
  StakePolicyBlock,
  Info,
  Text,
  ButtonsWrapper,
  InputWithError,
  InputContainer,
  InputAdditions,
  TokenName,
  ButtonMax,
  Separator,
  LabelBalance,
  Label,
  Balance,
  IconToken,
  IconWrapper,
};
