import styled from "styled-components";

const CountdownTimerWrapper = styled.div`
  display: flex;
  align-items: flex-start;
`;

const TimerItem = styled.div`
  div {
    &:first-child {
      display: flex;
      width: 2.75rem;
      height: 2.25rem;
      font-weight: 600;
      font-size: 1.25rem;
      align-items: center;
      border-radius: 0.5rem;
      margin-bottom: 0.375rem;
      justify-content: center;
      color: ${({ theme }) => theme.colors.blue};
      background-color: ${({ theme }) => theme.colors.buttonGreyOpacity};
    }

    &:last-child {
      font-weight: 500;
      line-height: 120%;
      text-align: center;
      font-size: 0.625rem;
      color: ${({ theme }) => theme.colors.greyDark};
    }
  }
`;

const Separator = styled.div`
  font-weight: 600;
  font-size: 1.25rem;
  margin: 0.25rem 0.6875rem 0 0.6875rem;
  color: ${({ theme }) => theme.colors.blue};
`;

export default {
  CountdownTimerWrapper,
  TimerItem,
  Separator,
};
