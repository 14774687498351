import { createSelector } from "@reduxjs/toolkit";

import { selectPreStaking } from "store/slices/prestakingData";
import { selectPrices } from "store/slices/prices";
import { selectTokens } from "store/slices/tokens";
import { selectUser } from "store/slices/user";

export const selectData = createSelector(
  [selectPreStaking, selectTokens, selectUser, selectPrices],
  (preStaking, tokens, user, prices) => ({
    user,
    preStaking,
    tokens,
    prices,
  })
);
