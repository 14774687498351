import { FORMAT_COMMA, FORMAT_DOT, FULFILLED } from "shared/constants";

export function isNotNullOrUndefined<T extends object>(input: null | undefined | T): input is T {
  return input !== null && input !== undefined;
}
export function assertFulfilled<T>(item: PromiseSettledResult<T>): item is PromiseFulfilledResult<T> {
  return item.status === FULFILLED;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const toMap = (array: Array<any>, getBy: string) =>
  array.reduce((acc, item) => ({ ...acc, [item[getBy]]: item }), {});

export const toArray = (map: { [key: string]: string | number | object }) => Object.values(map);

export const removeTrailingZeros = (amount: string) => {
  if (amount.includes(FORMAT_DOT) || amount.includes(FORMAT_COMMA)) {
    return amount.replace(/\.?0*$/, "");
  }
  return amount;
};
