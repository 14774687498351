import styled from "styled-components";

import { hoverTransition } from "shared/theme/styles";

const PartnersBlockWrapper = styled.div`
  width: 36rem;
  max-width: 90%;
  margin: 4.4375rem auto 5.625rem auto;
  ${({ theme }) => theme.mediaWidth("upToSmall", `max-width: 95%;`)}
`;

const LogoWrapper = styled.div`
  display: grid;
  grid-row-gap: 3.5rem;
  grid-column-gap: 3rem;
  justify-items: center;
  grid-template-columns: repeat(3, 1fr);

  svg {
    max-width: 100%;
  }

  ${({ theme }) => theme.mediaWidth("upToMedium", `margin: 4.4375rem auto 7.5rem auto;`)}
  ${({ theme }) => theme.mediaWidth("upToSmall", `grid-template-columns: repeat(2, 1fr);`)}
`;

const Link = styled.a`
  line-height: 0;
  transition: ${hoverTransition};

  &:hover {
    opacity: 0.8;
  }
`;

export default {
  PartnersBlockWrapper,
  LogoWrapper,
  Link,
};
