import styled from "styled-components";

const LogoWrapper = styled.div<{ width?: number }>`
  line-height: 0;

  svg {
    height: auto;
    width: ${({ width }) => (width ? `${width}rem` : "auto")};

    ${({ theme }) => theme.mediaWidth("upToSmall", `width: 12.8125rem;`)}
  }
`;

export default {
  LogoWrapper,
};
