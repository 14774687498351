import styled, { css } from "styled-components";

const TwoColumnListWrapper = styled.div`
  padding: 0 0.75rem;
`;

const Element = styled.div`
  gap: 1rem;
  display: flex;
  align-items: flex-start;
  margin-bottom: 1.1875rem;
  justify-content: space-between;

  &:last-child {
    margin-bottom: 0;
  }

  & > div {
    font-weight: 400;
    overflow: hidden;
    line-height: 120%;
    font-size: 0.75rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: ${({ theme }) => theme.colors.greyLight};

    &:first-child {
      width: 40%;
      text-align: left;
    }

    &:last-child {
      width: 60%;
      font-weight: 500;
      text-align: right;
      font-size: 0.875rem;
      color: ${({ theme }) => theme.colors.blue};
    }
  }
  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        & > div {
          width: auto !important;
        }
      `
    )}
`;

export default {
  TwoColumnListWrapper,
  Element,
};
