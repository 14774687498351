import { IControl } from "shared/interfaces";

export enum EButtonColors {
  RED = "RED",
  BLUE = "BLUE",
  GREY = "GREY",
  GREY_OPACITY = "GREY_OPACITY",
  TRANSPARENT = "TRANSPARENT",
}

export enum EButtonSizes {
  XS = "XS",
  S = "S",
  M = "M",
  L = "L",
  XL = "XL",
}

export interface IButtonStatusColors {
  default: string;
  active: string;
  hover: string;
  disabled: string;
}

export interface IButtonProps {
  name: string;
  handler: () => void;
  iconLeft?: JSX.Element;
  iconRight?: JSX.Element;
  fullWidth?: boolean;
  size?: EButtonSizes;
  color?: EButtonColors;
  disabled?: boolean;
  isAuth?: boolean;
  textColor?: string;
}

export interface IButtonIconProps {
  control?: IControl;
}
