import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IBalance } from "shared/interfaces";
import { RootState } from "store";

export interface IUserState {
  id: string;
  balances: { [key: string]: IBalance };
  loading: boolean;
  isLockupDeployed: boolean;
}

const initialState: IUserState = {
  id: "",
  balances: {},
  loading: true,
  isLockupDeployed: false,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserLoading: (s, { payload }: PayloadAction<boolean>) => void (s.loading = payload),
    setAccountId: (s, { payload }: PayloadAction<string>) => void (s.id = payload),
    setBalances: (s, { payload }: PayloadAction<{ [key: string]: IBalance }>) => void (s.balances = payload),
    setLockupDeployment: (s, { payload }: PayloadAction<boolean>) => void (s.isLockupDeployed = payload),
  },
});

export const selectUser = (s: RootState) => s.user;
export const selectAccountId = (s: RootState) => s.user.id;
export const selectBalances = (s: RootState) => s.user.balances;
export const selectUserLoading = (s: RootState) => s.user.loading;

export const { setAccountId, setBalances, setUserLoading, setLockupDeployment } = userSlice.actions;

export const userReducer = userSlice.reducer;
