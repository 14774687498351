import styled from "styled-components";

import externalStyles from "shared/components/BalancesTimerBlock/styles";

const PseudoIcon = styled.div`
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.greyAlt};
`;

const PseudoIconMini = styled(PseudoIcon)`
  width: 1.5rem;
  height: 1.5rem;
`;

const PseudoIconSquare = styled(PseudoIcon)`
  border-radius: 0.5rem;
`;

const PseudoButton = styled.div`
  height: 2.5rem;
  width: 4.3125rem;
  margin: 0 0 0 auto;
  border-radius: 0.5rem;
  background-color: ${({ theme }) => theme.colors.blue};
`;

const PseudoButtonGrey = styled.div`
  width: 6.5rem;
  height: 1.75rem;
  margin-top: 1rem;
  border-radius: 0.5rem;
  background-color: ${({ theme }) => theme.colors.greyAlt};
`;

const PseudoTitle = styled.div<{ widthInRem?: string }>`
  height: 1rem;
  margin-bottom: 0.5rem;
  border-radius: 0.25rem;
  background-color: ${({ theme }) => theme.colors.greyAlt};
  width: ${({ widthInRem }) => (widthInRem ? `${widthInRem}rem` : "10rem")};
`;

const PseudoDescription = styled.div<{ widthInRem?: string }>`
  height: 0.5rem;
  border-radius: 0.15rem;
  margin-bottom: 0.25rem;
  background-color: ${({ theme }) => theme.colors.greyAlt};
  width: ${({ widthInRem }) => (widthInRem ? `${widthInRem}rem` : "10rem")};

  &:last-child {
    margin-bottom: 0;
  }
`;

const AssetItemWrapper = styled(externalStyles.AssetItem)`
  display: flex;
  align-items: flex-start;

  svg {
    margin: 0 0.625rem;

    path {
      fill: ${({ theme }) => theme.colors.greyOpacity};
    }
  }

  ${({ theme }) => theme.mediaWidth("upToSmall", `flex-wrap: wrap;`)}
`;

const ButtonsContainer = styled.div`
  flex: 1;
  gap: 0.5rem;
  display: flex;
  align-items: center;

  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      `
        flex: unset;
        width: 100%;
      `
    )}
`;

const Column = styled.div<{ gapInRem?: string; spaceBetween?: boolean }>`
  width: 50%;
  display: flex;
  gap: ${({ gapInRem }) => (gapInRem ? `${gapInRem}rem` : "0.75rem")};
  ${({ theme, spaceBetween }) =>
    theme.mediaWidth(
      "upToSmall",
      `
        width: 100%;
        justify-content: ${spaceBetween ? "space-between" : "unset"}
      `
    )}
`;

const PseudoIconText = styled.div`
  gap: 0.5rem;
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;

  div {
    margin-bottom: 0;
  }
`;

const PseudoTimerWrapper = styled.div`
  text-align: center;
  margin-bottom: 2.25rem;

  div {
    &:last-child {
      justify-content: center;
    }
  }
`;

const PseudoTimerTitle = styled(PseudoTitle)`
  margin: 0 auto 1.25rem auto;
  background-color: ${({ theme }) => theme.colors.buttonGreyOpacity};
`;

const PseudoTimerSubtitle = styled(PseudoTitle)`
  width: 2.25rem;
  height: 0.5rem;
  margin: 0.5rem auto 0 auto;
  background-color: ${({ theme }) => theme.colors.buttonGreyOpacity};
`;

export default {
  PseudoIcon,
  PseudoButton,
  AssetItemWrapper,
  ButtonsContainer,
  PseudoIconSquare,
  PseudoTitle,
  PseudoDescription,
  PseudoButtonGrey,
  PseudoIconMini,
  Column,
  PseudoIconText,
  PseudoTimerWrapper,
  PseudoTimerTitle,
  PseudoTimerSubtitle,
};
