import { t } from "i18next";

import { ReactComponent as Bocachica } from "assets/images/partners/bocachica.svg";
import { ReactComponent as HAPI } from "assets/images/partners/hapi.svg";
import { ReactComponent as Jumbo } from "assets/images/partners/jumbo.svg";
import { ReactComponent as Lockup } from "assets/images/partners/lockup.svg";
import { ReactComponent as Near } from "assets/images/partners/near.svg";
import { ReactComponent as Pitchtalk } from "assets/images/partners/pitchtalk.svg";
import { H2 } from "shared/components/Typography";
import { linkOptions, partnersLinks } from "shared/constants";

import styles from "./styles";

export const PartnersBlock = () => {
  const partnersList = [
    {
      logo: <Jumbo />,
      link: partnersLinks.jumbo,
    },
    {
      logo: <Lockup />,
      link: partnersLinks.lockup,
    },
    {
      logo: <Near />,
      link: partnersLinks.near,
    },
    {
      logo: <HAPI />,
      link: partnersLinks.hapi,
    },
    {
      logo: <Pitchtalk />,
      link: partnersLinks.pitchtalk,
    },
    {
      logo: <Bocachica />,
      link: partnersLinks.bocachica,
    },
  ];

  return (
    <styles.PartnersBlockWrapper>
      <H2 text={t("partners.title")} isCentered />
      <styles.LogoWrapper>
        {partnersList.map((partner) => (
          <styles.Link href={partner.link} target={linkOptions.blank} key={partner.link}>
            {partner.logo}
          </styles.Link>
        ))}
      </styles.LogoWrapper>
    </styles.PartnersBlockWrapper>
  );
};
