import { createGlobalStyle } from "styled-components";

import { Theme } from "./index";

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    font-family: ${({ theme }: { theme: Theme }) => theme.fonts.interSerif};
  }

  
  body, #root {
    margin: 0;
    padding: 0;
    min-height: 100vh;
    position: relative;
  }
  
  body {
    padding: 1.5rem;
    user-select: none;
    -webkit-user-select: none;
    background-color: ${({ theme }) => theme.colors.black};
    
    @media (max-width: 599px) {
      padding: 0;
      background-color: ${({ theme }) => theme.colors.background};
    }
  }
  
  #root {
    color: ${({ theme }) => theme.colors.greyDark};
  }

  p {
    margin: 1rem 0;
    font-weight: 500;
    line-height: 160%;
    font-size: 0.875rem;
  }

  img {
    max-width: 100%;
  }

  a {
    font-weight: 600;
    font-size: 0.875rem;
    text-decoration: none;
  }

  .Toastify__toast-container {
    max-width: 27.625rem;
    width: fit-content;
    min-width: 20rem;
    @media (max-width: 480px) {
      max-width: 100%;
      width: 100vw;
    }
  }

  ::-webkit-scrollbar {
    height: 100%;
    width: 0.25rem;
  }

  ::-webkit-scrollbar-track {
    border-radius: 0.3125rem;
    background-color: ${({ theme }) => theme.colors.blue};
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 0.3125rem;
    background-color: ${({ theme }) => theme.colors.background};
  }
`;

export default GlobalStyle;
