import styled from "styled-components";

import { hoverTransition } from "shared/theme/styles";

const WalletButtonsWrapper = styled.div`
  gap: 1rem;
  display: flex;
  margin-bottom: 2rem;
  justify-content: center;

  ${({ theme }) => theme.mediaWidth("upToSmall", `flex-wrap: wrap;`)};

  button {
    display: grid;
    width: 13.5rem;
    cursor: pointer;
    overflow: hidden;
    column-gap: 1rem;
    height: 8.875rem;
    align-items: center;
    border-radius: 1.25rem;
    justify-content: center;
    grid-template-columns: 1fr;
    background-color: transparent;
    transition: ${hoverTransition};
    grid-template-rows: 1fr 2.5625rem;
    border: 1px solid ${({ theme }) => theme.colors.violet};

    ${({ theme }) =>
      theme.mediaWidth(
        "upToSmall",
        `
          width: 100%; 
          display: flex;
          height: 5.25rem;
          align-items: center; 
          padding-left: 1.75rem; 
          justify-content: flex-start;
        `
      )};

    &:hover {
      border: 1px solid ${({ theme }) => theme.colors.buttonGreyHover};
    }

    svg {
      width: 4rem;
      height: auto;
      margin: 0 auto;

      ${({ theme }) =>
        theme.mediaWidth(
          "upToSmall",
          `
            margin: 0;
            width: 2.25rem;
          `
        )}
    }

    span {
      width: 100%;
      height: 100%;
      display: block;
      font-weight: 600;
      font-size: 0.875rem;
      color: ${({ theme }) => theme.colors.blueDarken};

      ${({ theme }) => theme.mediaWidth("upToSmall", `width: unset; height: unset;`)}
    }
  }
`;

export default {
  WalletButtonsWrapper,
};
