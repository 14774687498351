import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IContractInfo } from "shared/interfaces/prestaking.interfaces";
import { RootState } from "store";

export interface IPreStakingState {
  userData: { [key: string]: string };
  contractInfo: IContractInfo;
  loading: boolean;
}

export const initialContractInfo = {
  ownerId: "",
  startDate: 0,
  endDate: 0,
  usersCount: 0,
  isLockStarted: false,
  isLockEnded: false,
  whitelistedTokens: {},
};

const initialState: IPreStakingState = {
  userData: {},
  contractInfo: initialContractInfo,
  loading: true,
};

const preStakingSlice = createSlice({
  name: "preStaking",
  initialState,
  reducers: {
    setUserData: (state, action: PayloadAction<{ [key: string]: string }>) => {
      state.userData = action.payload;
    },
    setContractInfo: (state, action: PayloadAction<IContractInfo>) => {
      state.contractInfo = action.payload;
    },
    setPreStakingLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
  },
});

export const selectPreStaking = (state: RootState) => state.preStaking;
export const selectPreStakingLoading = (state: RootState) => state.preStaking.loading;

export const { setUserData, setContractInfo, setPreStakingLoading } = preStakingSlice.actions;

export const preStakingReducer = preStakingSlice.reducer;
