import { useFormContext } from "react-hook-form";

import { IInputProps } from "./interface";
import styles from "./styles";

export const Input: React.FC<IInputProps> = ({ id, value, error = false, ...rest }: IInputProps) => {
  const { register } = useFormContext();
  const props = rest.disabled ? { value } : { ...register(id) };

  return (
    <styles.InputWrapper
      {...rest}
      {...props}
      error={error}
      autoComplete="off"
      autoCorrect="off"
      type={rest.type || "text"}
      onWheel={(event) => event.currentTarget.blur()}
    />
  );
};
