import { ReactComponent as CloseIcon } from "assets/images/icons/close.svg";
import { ButtonIcon } from "shared/components/Buttons/ButtonIcon";
import { useAppDispatch } from "shared/hooks/redux/useAppDispatch";
import { closeModal } from "store/slices/modals";

import { IModalHeaderProps } from "./interface";
import styles from "./styles";

export function ModalHeader({ title, isTitleCentered = false, showTitle = true }: IModalHeaderProps) {
  const dispatch = useAppDispatch();
  const closeControl = {
    icon: <CloseIcon />,
    handler: () => dispatch(closeModal()),
  };

  return (
    <styles.ModalHeaderWrapper>
      {title && showTitle && <styles.Title isCentered={isTitleCentered}>{title}</styles.Title>}
      <ButtonIcon control={closeControl} />
    </styles.ModalHeaderWrapper>
  );
}
