import { t } from "i18next";
import { useState } from "react";

import { SlideDown } from "shared/components/Animations";
import { ButtonEmpty } from "shared/components/Buttons/ButtonEmpty";
import { Translate } from "shared/components/Translate";
import { H1, H3 } from "shared/components/Typography/styles";

import styles from "./styles";

export const InfoBlock = () => {
  const [showDescription, setShowDescription] = useState<boolean>(false);

  return (
    <styles.InfoBlockWrapper>
      <styles.TitleSubtitleContainer>
        <H1 isCentered>
          <Translate value="pages.home.h1" />
        </H1>
        <H3 isCentered>
          <Translate value="pages.home.h3" />
        </H3>
      </styles.TitleSubtitleContainer>
      <p>
        <Translate value="pages.home.shortDescription.p1" />
      </p>
      <p>
        <Translate value="pages.home.shortDescription.p2" />
      </p>
      <SlideDown show={showDescription}>
        <p>
          <Translate value="pages.home.details.p1" />
        </p>
        <p>
          <Translate value="pages.home.details.p2" />
        </p>
        <p>
          <Translate value="pages.home.details.p3" />
        </p>
      </SlideDown>
      <ButtonEmpty
        name={showDescription ? t("actions.less") : t("actions.more")}
        handler={() => setShowDescription(!showDescription)}
      />
    </styles.InfoBlockWrapper>
  );
};
