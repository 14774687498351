import { ReactComponent as MyNearWallet } from "assets/images/wallets-logo/my-near-wallet.svg";
import { ReactComponent as Sender } from "assets/images/wallets-logo/sender.svg";
import { EWalletName } from "shared/interfaces/wallet.interfaces";

export const wallets = [
  { key: EWalletName.MY_NEAR_WALLET, name: "wallets.myNearWallet", logo: MyNearWallet },
  { key: EWalletName.SENDER, name: "wallets.sender", logo: Sender },
];

export const NEAR_TOKEN_ID = "NEAR";
export const TOKEN_KEY = "token";

export const ONE_YOCTO = "1";
export const DEFAULT_GAS = "60";
export const DEFAULT_CLAIM_GAS = "150";
export const TRANSACTION_GAS_PRICE = "100";
export const STORAGE_TO_REGISTER_FT = "0.1";
export const STORAGE_TO_REGISTER_WNEAR = "0.00125";

export const TOKEN_CONTRACT_HAPI = "d9c2d319cd7e6177336b0a9c93c21cb48d84fb54.factory.bridge.near";
export const TOKEN_CONTRACT_CHICA = "token.bocachica_mars.near";
export const TOKEN_CONTRACT_JUMBO = "token.jumbo_exchange.near";

export const TOKEN_TEST_1 = "ref.fakes.testnet";
export const TOKEN_TEST_2 = "eth.fakes.testnet";
export const TOKEN_TEST_3 = "jumbo_exchange_v2.solniechniy.testnet";
