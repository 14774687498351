import { t } from "i18next";

import { senderWalletUrl } from "services/config";
import ToastService from "shared/components/Toast";
import { DEFAULT_GAS, linkOptions } from "shared/constants";
import { BigNumber } from "shared/utils/calculations";

const BASE = 10;
const GAS_UNITS = 12;
const Tgas = BigNumber(1).times(BigNumber(BASE).pow(GAS_UNITS)).toFixed();

export const convertGas = (gas?: string): string =>
  gas ? BigNumber(gas).times(Tgas).toFixed() : BigNumber(DEFAULT_GAS).times(Tgas).toFixed();

export const setupSenderWallet = async () => {
  if (!window.near) {
    window.open(senderWalletUrl, linkOptions.blank, linkOptions.noreferrer);
  } else {
    ToastService.error({ text: t("toast.error.noAccountExist") });
  }
};
