export interface IFactoryContract {
  getLockupId({ accountId }: { accountId: string }): Promise<string | undefined>;
}

export enum FactoryMethods {
  getLockupContractId = "get_lockup_contract_id", // (account_id: AccountId)
}

export const FactoryChangeMethods = {
  deployLockup: "deploy_lockup_contract",
};
