import { PropsWithChildren, useRef, useState } from "react";

import { ReactComponent as MenuIcon } from "assets/images/icons/menu-alt.svg";
import { ButtonIcon } from "shared/components/Buttons/ButtonIcon";
import useOnClickOutside from "shared/hooks/useOnClickOutside";

import styles from "./styles";

export const MenuAdditional: React.FC<PropsWithChildren> = ({ children }: PropsWithChildren) => {
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const node = useRef<HTMLDivElement>(null);
  useOnClickOutside(node, () => setShowMenu(false));

  return (
    <styles.MenuWrapper ref={node}>
      <ButtonIcon control={{ icon: <MenuIcon />, handler: () => setShowMenu(!showMenu) }} />
      {showMenu && <styles.Menu>{children}</styles.Menu>}
    </styles.MenuWrapper>
  );
};
