import RPCProviderService from "services/RPCProviderService";
import { DEFAULT_GAS, DEFAULT_CLAIM_GAS, ONE_YOCTO } from "shared/constants";
import { formatPreStakingContractInfo } from "shared/helpers/prestaking";
import { ITransaction } from "shared/interfaces";
import { IContractInfo } from "shared/interfaces/prestaking.interfaces";

import { PreStakingChangeMethods, PreStakingViewMethods } from "./interfaces";

export default class PreStakingContract {
  readonly contractId: string;

  private provider: RPCProviderService;

  public constructor({ provider, contractId }: { provider: RPCProviderService; contractId: string }) {
    this.contractId = contractId;
    this.provider = provider;
  }

  async getUser({ userId }: { userId: string }): Promise<{ [key: string]: string } | undefined> {
    if (!userId) return;
    const user = await this.provider.viewFunction(PreStakingViewMethods.getUser, this.contractId, { user_id: userId });

    return user ? user : {};
  }

  async getContractInfo(): Promise<IContractInfo | undefined> {
    const contractInfo = await this.provider.viewFunction(PreStakingViewMethods.getContractInfo, this.contractId);
    if (!contractInfo) return;

    return formatPreStakingContractInfo(contractInfo);
  }

  async getJoinFee(): Promise<string | undefined> {
    return this.provider.viewFunction(PreStakingViewMethods.getJoinFee, this.contractId);
  }

  async createJoinTransaction(): Promise<ITransaction | undefined> {
    try {
      const joinFee = await this.getJoinFee();
      if (!joinFee) return;

      return {
        receiverId: this.contractId,
        functionCalls: [
          {
            methodName: PreStakingChangeMethods.JOIN,
            amount: joinFee,
            gas: DEFAULT_GAS,
          },
        ],
      };
    } catch (error) {
      console.error("Error during create join transaction: ", error);
    }
  }

  async createClaimTransaction(): Promise<ITransaction | undefined> {
    return {
      receiverId: this.contractId,
      functionCalls: [
        {
          methodName: PreStakingChangeMethods.CLAIM,
          amount: ONE_YOCTO,
          gas: DEFAULT_CLAIM_GAS,
        },
      ],
    };
  }

  async createUnlockFundsTransaction(tokenId: string): Promise<ITransaction | undefined> {
    return {
      receiverId: this.contractId,
      functionCalls: [
        {
          methodName: PreStakingChangeMethods.UNLOCK_FUNDS,
          amount: ONE_YOCTO,
          gas: DEFAULT_GAS,
          args: {
            token_id: tokenId,
          },
        },
      ],
    };
  }
}
