import styled from "styled-components";

const PoolInformationItem = styled.div`
  padding: 1rem;
  border-radius: 1.25rem;
  background-color: ${({ theme }) => theme.colors.blueLight};
`;

const TotalReward = styled(PoolInformationItem)`
  grid-area: reward;
`;

const APR = styled(PoolInformationItem)`
  grid-area: apr;
`;

const Duration = styled(PoolInformationItem)`
  grid-area: duration;
`;

const TotalStakers = styled(PoolInformationItem)`
  grid-area: stakers;
`;

const TotalStaked = styled(PoolInformationItem)`
  padding: 0;
  display: flex;
  grid-area: staked;

  ${({ theme }) => theme.mediaWidth("upToSmall", `align-items: center;`)}
`;

const TotalStakedDataWrapper = styled.div`
  flex: 1;
  padding: 1rem;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      `
        flex-wrap: wrap;

        > div {
            width: 100%;

            &:last-child {
                margin-top: 2rem;
            }
        }
      `
    )}
`;

const StakingPoolInformationWrapper = styled.div`
  gap: 0.75rem;
  display: grid;
  max-width: 90%;
  width: 49.5rem;
  margin: 0 auto;
  padding: 0.75rem;
  border-radius: 2rem;
  margin-bottom: 2.25rem;
  grid-template-areas:
    "reward apr duration stakers"
    "reward staked staked staked";
  grid-template-columns: 15rem 1fr 1fr 1fr;
  background-color: ${({ theme }) => theme.colors.blue};
  ${({ theme }) => theme.mediaWidth("upToSmall", `max-width: 100%;`)}

  ${({ theme }) =>
    theme.mediaWidth(
      "upToMedium",
      `
      grid-template-columns: unset;
      grid-template-areas:
        "reward reward reward reward"
        "apr apr apr apr"
        "duration duration stakers stakers"
        "staked staked staked staked";
      `
    )}
`;

const Title = styled.div<{ color?: string }>`
  display: flex;
  gap: 0.5rem;
  font-weight: 500;
  line-height: 120%;
  font-size: 0.75rem;
  margin-bottom: 0.25rem;
  color: rgba(${({ theme }) => theme.colors.whiteRGB}, 0.8);
`;

const Value = styled.div`
  font-weight: 700;
  line-height: 120%;
  font-size: 1.5rem;
  color: ${({ theme, color }) => (color ? color : theme.colors.white)};
`;

const RewardTokensList = styled.div`
  margin-top: 0.25rem;
`;

const RewardTokenItem = styled.div`
  gap: 0.5rem;
  display: flex;
  margin-top: 0.5rem;
  align-items: center;
`;

const Icon = styled.img<{ color?: string }>`
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  background-color: ${({ color }) => color};
`;

const TokenAmount = styled.div`
  font-size: 1rem;
  font-weight: 500;
  line-height: 120%;
  color: ${({ theme }) => theme.colors.white};
`;

const ChartWrapper = styled.div`
  width: 4.625rem;
  height: 4.625rem;
  margin-right: 1rem;
  margin-left: 0.75rem;

  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      `
        width: 7.5rem;
        height: 7.5rem;
      `
    )}
`;

const Circle = styled.div<{ color: string }>`
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 50%;
  border: 3px solid ${({ color }) => color};
`;

const StakeTokenItem = styled(RewardTokenItem)`
  margin-top: 0.375rem;

  &:first-child {
    margin-top: 0;
  }
`;

const StakeTokenItemValue = styled.div`
  line-height: 1;
  font-size: 0.75rem;
  color: ${({ theme }) => theme.colors.white};
`;

export default {
  StakingPoolInformationWrapper,
  TotalReward,
  APR,
  Duration,
  TotalStakers,
  TotalStaked,
  Title,
  TotalStakedDataWrapper,
  Value,
  RewardTokensList,
  RewardTokenItem,
  Icon,
  TokenAmount,
  ChartWrapper,
  Circle,
  StakeTokenItem,
  StakeTokenItemValue,
};
