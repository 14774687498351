import styled, { css, keyframes } from "styled-components";

const fadeInTop = keyframes`
  from {
    opacity: 0.2;
    transform: translate(-50%, 10px);
  }
  to {
    opacity: 1;
    transform: translate(-50%, 0);
  }
`;

const Container = styled.div`
  position: relative;
  display: flex;
  z-index: 3;

  svg {
    pointer-events: none;
  }
  cursor: pointer;
`;

const HoverContent = styled.div<{ show: boolean }>`
  position: absolute;
  left: 50%;
  bottom: 1.563rem;
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.black};
  border-radius: 0.5rem;
  padding: 0.5rem;
  font-style: normal;
  font-weight: normal;
  font-size: 0.75rem;
  line-height: 1.063;
  width: max-content;
  text-align: center;
  max-width: 15.625rem;
  z-index: 1;
  box-shadow: 0px 3px 12px 0px rgb(0 0 0 / 15%);
  ${({ show }) =>
    show &&
    css`
      animation-name: ${fadeInTop};
      animation-duration: 200ms;
      animation-fill-mode: forwards;
    `}
`;

export default {
  Container,
  HoverContent,
};
