import { helperUrl } from "services/config";

import { fetchSendJson } from "./index";

export const getUserWalletTokens = async (accountId: string): Promise<string[]> => {
  const url = `${helperUrl}/account/${accountId}/likelyTokens`;
  try {
    if (!accountId) return [];
    return await fetchSendJson({ url });
  } catch (e) {
    console.warn(`Error ${e} while loading likely tokens from \n ${url}`);
    return [];
  }
};
