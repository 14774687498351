import isEmpty from "lodash/isEmpty";
import { useState, useEffect } from "react";

import { FOUR, ZERO_STRING } from "shared/constants";
import { IBalance, ITokenPrice } from "shared/interfaces";
import { BigNumber, formatUnits } from "shared/utils/calculations";
import { selectPrestakingData } from "store/selectors/selectPrestakingData";
import { selectPrices } from "store/slices/prices";
import { selectRewardAccountBalances } from "store/slices/rewardAccountData";

import { useAppSelector } from "./redux/useAppSelector";

interface IRewardData {
  rewardByTokens: { [key: string]: string };
  rewardByTokensFiat: { [key: string]: string };
  rewardAmountFiat: number;
  userStakedAmountFiat: number;
  userStakedPercentage: number;
  userEstimatedReward: number;
  totalStakedFiat: number;
}

const rewardDataInitial = {
  rewardByTokens: {},
  rewardByTokensFiat: {},
  rewardAmountFiat: 0,
  userStakedAmountFiat: 0,
  userStakedPercentage: 0,
  userEstimatedReward: 0,
  totalStakedFiat: 0,
};

const convertToFiat = (
  tokens: { [key: string]: string },
  prices: {
    [key: string]: ITokenPrice;
  },
  rewardBalances: {
    [key: string]: IBalance;
  }
) => {
  if (isEmpty(tokens) || isEmpty(prices) || isEmpty(rewardBalances)) return 0;

  let totalAmount = 0;

  for (const tokenId in tokens) {
    const tokenFiatReward = BigNumber(tokens[tokenId]).mul(prices[tokenId]?.price || 0);
    const tokenFiatRewardFormatted = formatUnits(tokenFiatReward, rewardBalances[tokenId].decimal);

    totalAmount += Number(tokenFiatRewardFormatted);
  }

  return totalAmount;
};

const useGetRewardData = (inputValue = ZERO_STRING) => {
  const prices = useAppSelector(selectPrices);
  const rewardBalances = useAppSelector(selectRewardAccountBalances);
  const { userStakingBalances, whitelistedTokens } = useAppSelector(selectPrestakingData);

  const [rewardData, setRewardData] = useState<IRewardData>(rewardDataInitial);

  useEffect(() => {
    const tokensEstimatedReward: { [key: string]: string } = {};
    const tokensFiatRewardBalances: { [key: string]: string } = {};

    for (const tokenId in rewardBalances) {
      tokensFiatRewardBalances[tokenId] = rewardBalances[tokenId].balance;
    }

    const totalFiatReward = convertToFiat(tokensFiatRewardBalances, prices, rewardBalances);
    const totalStakedAmountFiat = convertToFiat(whitelistedTokens, prices, rewardBalances);
    const userTotalStakedAmountFiat = convertToFiat(userStakingBalances, prices, rewardBalances);

    const stakedPercentage =
      (Number(inputValue) + userTotalStakedAmountFiat) / (Number(inputValue) + totalStakedAmountFiat || 1);
    const estimatedReward = totalFiatReward * stakedPercentage;

    for (const tokenId in rewardBalances) {
      tokensEstimatedReward[tokenId] = formatUnits(
        BigNumber(rewardBalances[tokenId].balance).mul(stakedPercentage),
        rewardBalances[tokenId].decimal,
        FOUR
      );
    }

    setRewardData({
      rewardByTokens: tokensEstimatedReward,
      rewardByTokensFiat: tokensFiatRewardBalances,
      rewardAmountFiat: totalFiatReward,
      userStakedAmountFiat: userTotalStakedAmountFiat,
      userStakedPercentage: stakedPercentage,
      userEstimatedReward: estimatedReward,
      totalStakedFiat: totalStakedAmountFiat,
    });
  }, [inputValue, prices, rewardBalances, userStakingBalances, whitelistedTokens]);

  return rewardData;
};

export default useGetRewardData;
