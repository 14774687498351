import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IBalance } from "shared/interfaces";
import { RootState } from "store";

export interface IRewardAccountDataState {
  balances: { [key: string]: IBalance };
  loading: boolean;
}

const initialState: IRewardAccountDataState = {
  balances: {},
  loading: true,
};

const rewardAccountDataSlice = createSlice({
  name: "rewardAccountData",
  initialState,
  reducers: {
    setRewardAccountBalances: (s, { payload }: PayloadAction<{ [key: string]: IBalance }>) =>
      void (s.balances = payload),
    setRewardAccountLoading: (s, action: PayloadAction<boolean>) => {
      s.loading = action.payload;
    },
  },
});

export const selectRewardAccountData = (s: RootState) => s.rewardAccountData;
export const selectRewardAccountBalances = (s: RootState) => s.rewardAccountData.balances;
export const selectRewardAccountDataLoading = (s: RootState) => s.rewardAccountData.loading;

export const { setRewardAccountBalances, setRewardAccountLoading } = rewardAccountDataSlice.actions;

export const rewardAccountDataReducer = rewardAccountDataSlice.reducer;
