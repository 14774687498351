import { Sim } from "shared/theme/sim";

import { ECornerPosition } from "./enum";
import styles from "./styles";

export function SimBackground() {
  return (
    <>
      <Sim />
      <styles.Limiters />
      <styles.Corner position={ECornerPosition.TOP_LEFT} />
      <styles.Corner position={ECornerPosition.TOP_RIGHT} />
      <styles.Corner position={ECornerPosition.BOTTOM_RIGHT} />
      <styles.SimCorner />
      <styles.SimWrapper>
        <styles.Sim />
      </styles.SimWrapper>
    </>
  );
}
