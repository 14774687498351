import styled from "styled-components";

import { hoverTransition } from "shared/theme/styles";

const ModalHeaderWrapper = styled.div<{ showTitle?: boolean }>`
  top: 0;
  z-index: 9;
  width: 100%;
  display: flex;
  position: sticky;
  align-items: "center";
  background: ${({ theme }) => theme.colors.white};
  justify-content: ${({ showTitle }) => (showTitle ? "unset" : "flex-end")};
  ${({ theme }) => theme.mediaWidth("upToSmall", `padding: 0 0.5rem;`)}

  svg {
    transition: ${hoverTransition};

    &:hover {
      opacity: 0.4;
    }
  }
`;

const Title = styled.span<{ isCentered?: boolean }>`
  flex: 1;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 1.8125rem;
  color: ${({ theme }) => theme.colors.blue};
  padding-left: ${({ isCentered }) => (isCentered ? "1.5rem" : "0")};
  text-align: ${({ isCentered }) => (isCentered ? "center" : "left")};
`;

export default {
  ModalHeaderWrapper,
  Title,
};
