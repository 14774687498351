import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import * as M from "shared/components/modals";
import { IModalProps, IStakingModal, ITooltipModal } from "shared/interfaces";
import { RootState } from "store";

export enum EModals {
  EMPTY = "EMPTY",
  CONNECT_WALLET_MODAL = "CONNECT_WALLET_MODAL",
  STAKING_MODAL = "STAKING_MODAL",
  ACCOUNT_MODAL = "ACCOUNT_MODAL",
  TOOLTIP_MODAL = "TOOLTIP_MODAL",
}

export type IModalsProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [EModals.EMPTY]: any;
  [EModals.CONNECT_WALLET_MODAL]: IModalProps;
  [EModals.STAKING_MODAL]: IStakingModal;
  [EModals.ACCOUNT_MODAL]: IModalProps;
  [EModals.TOOLTIP_MODAL]: ITooltipModal;
};

export type IModals = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [EModals.EMPTY]: any;
  [EModals.CONNECT_WALLET_MODAL]: React.FC<IModalProps>;
  [EModals.STAKING_MODAL]: React.FC<IStakingModal>;
  [EModals.ACCOUNT_MODAL]: React.FC<IModalProps>;
  [EModals.TOOLTIP_MODAL]: React.FC<ITooltipModal>;
};

export const MODALS: IModals = {
  [EModals.EMPTY]: null,
  [EModals.CONNECT_WALLET_MODAL]: M.ConnectWalletModal,
  [EModals.STAKING_MODAL]: M.StakingModal,
  [EModals.ACCOUNT_MODAL]: M.AccountModal,
  [EModals.TOOLTIP_MODAL]: M.TooltipModal,
};

export type ModalProps<T extends EModals> = Omit<IModalsProps[T], "closeModal">;

interface IModalState {
  modal: EModals;
  props: IModalsProps[EModals];
}

const initialState: IModalState = {
  modal: EModals.EMPTY,
  props: null,
};

const modalsSlice = createSlice({
  name: "modals",
  initialState,
  reducers: {
    showModal: (_, action: PayloadAction<IModalState>) => action.payload,
    closeModal: () => initialState,
  },
});

// SELECTORS
export const selectModalState = (state: RootState) => state.modals;

// ACTIONS
export const { showModal, closeModal } = modalsSlice.actions;

export const modalsReducer = modalsSlice.reducer;
