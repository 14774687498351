import styled from "styled-components";

const ButtonsGroupWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;

  button {
    &:first-child {
      margin-right: 1.75rem;
    }

    &:last-child {
      min-width: 12.5rem;

      ${({ theme }) => theme.mediaWidth("upToSmall", `min-width: 7.5rem;`)}
    }
  }
`;

export default { ButtonsGroupWrapper };
