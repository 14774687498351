import { AnimatePresence } from "framer-motion";
import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import { ThemeProvider } from "styled-components";

import { useWalletSelector, WalletSelectorContextProvider } from "providers/WalletProvider";
import { AppRoutes } from "routes";
import { contractId } from "services/config";
import useTransactionHash from "services/receiptsService";
import Header from "shared/components/Header";
import { SimBackground } from "shared/components/SimBackground";
import { EDimensions } from "shared/constants";
import { useAppDispatch } from "shared/hooks/redux/useAppDispatch";
import { useAppSelector } from "shared/hooks/redux/useAppSelector";
import useWindowDimensions from "shared/hooks/useWindowDimensions";
import theme, { GlobalStyle } from "shared/theme";
import store from "store";
import { loadPreStakingData } from "store/actions/loadPrestakingData";
import { closeModal, EModals, IModalsProps, MODALS, selectModalState } from "store/slices/modals";

import reportWebVitals from "./reportWebVitals";

import "services/translation";
import "react-toastify/dist/ReactToastify.css";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

function AppWrapper() {
  useTransactionHash();
  const dispatch = useAppDispatch();
  const dimensions = useWindowDimensions();
  const { RPCProvider } = useWalletSelector();
  const modalState = useAppSelector(selectModalState);
  const Modal: IModalsProps[EModals] = MODALS[modalState.modal];

  useEffect(() => {
    dispatch(loadPreStakingData({ provider: RPCProvider, contractId }));
  }, [RPCProvider, dispatch]);

  return (
    <>
      <Header />
      {dimensions !== EDimensions.SMALL && dimensions !== EDimensions.UNKNOWN && <SimBackground />}
      <AnimatePresence>
        {Modal && <Modal closeModal={() => dispatch(closeModal())} {...modalState.props} />}
      </AnimatePresence>
      <div>
        <GlobalStyle />
        <AppRoutes />
        <ToastContainer />
      </div>
    </>
  );
}

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <WalletSelectorContextProvider>
          <AppWrapper />
        </WalletSelectorContextProvider>
      </Provider>
    </ThemeProvider>
  </React.StrictMode>
);

reportWebVitals();
