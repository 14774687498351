import { IConfig } from "../config";

export default {
  networkId: "mainnet",
  nodeUrl: "https://rpc.mainnet.near.org",
  walletUrl: "https://wallet.near.org",
  helperUrl: "https://api.kitwallet.app",
  explorerUrl: "https://explorer.mainnet.near.org",
  contractId: "contract-v1.hapi_treasury_v1.near",
  rewardContractId: "dao_treasury_hapi.near",
  wNearAddress: "wrap.near",
  usn: "usn",
  myNearWalletUrl: "https://app.mynearwallet.com/",
  refFinanceIndexerUrl: "https://indexer.ref.finance",
  jumboExchangeIndexerUrl: "https://price-service.jumbo.exchange",
  walletDomain: ".near",
  senderWalletUrl: "https://sender.org/?origin=ref",
  lockupContractId: "factory-v1.lock-up.near",
} as IConfig;
