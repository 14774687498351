import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import FungibleTokenContract from "services/contracts/FungibleToken";
import { RootState } from "store";

interface ITokensState {
  tokens: { [key: string]: FungibleTokenContract };
  loading: boolean;
}

const initialState: ITokensState = {
  tokens: {},
  loading: true,
};

export const tokensSlice = createSlice({
  name: "tokens",
  initialState,
  reducers: {
    setTokens: (state, action: PayloadAction<{ [key: string]: FungibleTokenContract }>) => {
      state.tokens = action.payload;
    },
    setTokensLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
  },
});

// SELECTORS
export const selectTokens = (state: RootState) => state.tokens.tokens;
export const selectTokensLoading = (state: RootState) => state.tokens.loading;

// ACTIONS
export const { setTokens, setTokensLoading } = tokensSlice.actions;

export const tokensReducer = tokensSlice.reducer;
