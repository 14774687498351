import { zodResolver } from "@hookform/resolvers/zod";
import { t } from "i18next";
import { FormProvider, SubmitHandler, useForm, useWatch } from "react-hook-form";
import { Trans } from "react-i18next";

import { ReactComponent as InfoIcon } from "assets/images/icons/info.svg";
import { useWalletSelector } from "providers/WalletProvider";
import { ButtonsGroup } from "shared/components/Buttons/ButtonsGroup";
import Checkbox from "shared/components/Form/components/Checkbox";
import InputError from "shared/components/Form/components/Error";
import { Input } from "shared/components/Form/components/Input";
import { Translate } from "shared/components/Translate";
import TwoColumnList from "shared/components/TwoColumnList";
import { CONNECT_WALLET_MODAL_WIDTH_IN_REM, EMPTY_STRING, TWO } from "shared/constants";
import { useAppDispatch } from "shared/hooks/redux/useAppDispatch";
import { useAppSelector } from "shared/hooks/redux/useAppSelector";
import useGetRewardData from "shared/hooks/useGetRewardData";
import { IStakingModal } from "shared/interfaces";
import { BigNumber, calculateAPY, convertToDecimalsNumber } from "shared/utils/calculations";
import { getDifferenceBetweenNanosecInDays } from "shared/utils/getDifferenceBetweenNanosecInDays";
import { nanosecToUtcDate } from "shared/utils/nanosecToUtcDate";
import { stake } from "store/actions/stake";
import { selectBalanceData } from "store/selectors/selectBalance";
import { selectPreStaking } from "store/slices/prestakingData";
import { selectPrices } from "store/slices/prices";

import { IStakingFields } from "./interface";
import styles from "./styles";
import { stakingFormValidationSchema } from "./validationSchema";
import { ModalHeader } from "../components/ModalHeader";
import ModalWrapper from "../ModalWrapper";

export const StakingModal: React.FC<IStakingModal> = ({ closeModal, whiteListTokenId }: IStakingModal) => {
  const dispatch = useAppDispatch();
  const { RPCProvider, requestSignTransactions } = useWalletSelector();

  const { contractInfo } = useAppSelector(selectPreStaking);
  const { balance, symbol, icon, decimals } = useAppSelector((state) => selectBalanceData(state, whiteListTokenId));
  const tokenPrices = useAppSelector(selectPrices);
  const sessionInterval = getDifferenceBetweenNanosecInDays(contractInfo.startDate, contractInfo.endDate);

  const methods = useForm<IStakingFields>({
    mode: "all",
    reValidateMode: "onChange",
    resolver: zodResolver(stakingFormValidationSchema(balance)),
  });

  const {
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    control,
  } = methods;

  const depAmount = useWatch({ name: "depositAmount", control });
  const depAmountFiat = BigNumber(depAmount)
    .mul(tokenPrices[whiteListTokenId]?.price || 0)
    .toFixed(TWO);
  const { userEstimatedReward, totalStakedFiat, rewardAmountFiat } = useGetRewardData(depAmountFiat);

  const stakingDataList = [
    {
      title: t("modals.staking.main.list.apy"),
      value: t("modals.staking.main.list.apyValue", {
        value: calculateAPY(sessionInterval, totalStakedFiat, rewardAmountFiat, depAmountFiat),
      }),
    },
    {
      title: t("modals.staking.main.list.estimatedEarnings"),
      value: t("modals.staking.main.list.estimatedEarningsValue", {
        amount: userEstimatedReward.toFixed(TWO),
        days: sessionInterval,
      }),
    },
    {
      title: t("modals.staking.main.list.startOfStaking"),
      value: nanosecToUtcDate(contractInfo.startDate),
    },
    {
      title: t("modals.staking.main.list.endOfStaking"),
      value: nanosecToUtcDate(contractInfo.endDate),
    },
    {
      title: t("modals.staking.main.list.dateOfClaiming"),
      value: nanosecToUtcDate(contractInfo.endDate),
    },
  ];

  const onSubmit: SubmitHandler<IStakingFields> = (data) => {
    const decimalsAmount = convertToDecimalsNumber(data.depositAmount, decimals);
    stakeHandler(decimalsAmount);
    setValue("depositAmount", EMPTY_STRING);
    setValue("privacyAgreement", false);
    closeModal();
  };

  const setMaxValue = () => {
    if (BigNumber(balance).eq(0)) return;
    setValue("depositAmount", balance);
  };

  const stakeHandler = (amount: string) => {
    dispatch(
      stake({
        amount: amount,
        tokenId: whiteListTokenId,
        provider: RPCProvider,
        requestSignTransactions,
      })
    );
  };

  return (
    <ModalWrapper closeModal={closeModal} widthInRem={CONNECT_WALLET_MODAL_WIDTH_IN_REM}>
      <ModalHeader title={t("modals.staking.title")} />
      <FormProvider {...methods}>
        <styles.StakeForm onSubmit={(e) => e.preventDefault()}>
          <styles.StakeMainBlock>
            <div>
              <styles.LabelBalance>
                <styles.Label>
                  <Translate value="modals.staking.inputs.amount.label" />
                </styles.Label>
                <styles.Balance>
                  <Translate value="common.balance" />
                  <span>
                    <Trans i18nKey="common.balanceAmount" values={{ amount: balance, symbol }} />
                  </span>
                </styles.Balance>
              </styles.LabelBalance>
              <styles.InputWithError>
                <styles.InputContainer>
                  {icon && (
                    <styles.IconWrapper>
                      <styles.IconToken src={icon} />
                    </styles.IconWrapper>
                  )}
                  <styles.InputAdditions>
                    <styles.TokenName>{symbol}</styles.TokenName>
                    <styles.Separator />
                    <styles.ButtonMax onClick={setMaxValue}>
                      <Translate value="actions.max" />
                    </styles.ButtonMax>
                  </styles.InputAdditions>
                  <Input
                    id="depositAmount"
                    placeholder="0.0"
                    type="number"
                    step="any"
                    error={Boolean(errors.depositAmount?.message)}
                  />
                </styles.InputContainer>
                {errors.depositAmount?.message && <InputError text={errors.depositAmount.message} />}
              </styles.InputWithError>
            </div>
            <TwoColumnList list={stakingDataList} />
          </styles.StakeMainBlock>
          <styles.StakePolicyBlock>
            <styles.Info>
              <InfoIcon />
              <styles.Text>
                <p>
                  <Translate value="modals.staking.privacy.p1" />
                </p>
              </styles.Text>
            </styles.Info>
            <Checkbox id="privacyAgreement" label="modals.staking.inputs.agreement.label" />
          </styles.StakePolicyBlock>
          <styles.ButtonsWrapper>
            <ButtonsGroup
              cancelButtonHandler={closeModal}
              rightButton={{
                name: t("actions.confirm"),
                handler: handleSubmit(onSubmit),
                disabled: !isValid,
              }}
            />
          </styles.ButtonsWrapper>
        </styles.StakeForm>
      </FormProvider>
    </ModalWrapper>
  );
};
