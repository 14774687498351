export enum EWalletType {
  BROWSER = "browser",
  INJECTED = "injected",
}

export enum EWalletName {
  NEAR_WALLET = "near-wallet",
  SENDER = "sender",
  MY_NEAR_WALLET = "my-near-wallet",
}
