import { EButtonColors, EButtonSizes, IButtonProps } from "./interfaces";
import styles from "./styles";

export function ButtonEmpty({
  name,
  handler,
  iconLeft,
  iconRight,
  fullWidth = false,
  size = EButtonSizes.M,
  color = EButtonColors.TRANSPARENT,
}: IButtonProps) {
  return (
    <styles.ButtonEmptyWrapper type="button" onClick={handler} color={color} fullWidth={fullWidth} size={size}>
      {iconLeft || null}
      {name}
      {iconRight || null}
    </styles.ButtonEmptyWrapper>
  );
}
