import { ReactComponent as CloseIcon } from "assets/images/icons/close.svg";
import { ITooltipModal } from "shared/interfaces";

import styles from "./styles";
import ModalWrapper from "../ModalWrapper";

export const TooltipModal: React.FC<ITooltipModal> = ({ title, closeModal }: ITooltipModal) => {
  return (
    <ModalWrapper closeModal={closeModal}>
      <styles.Header>
        <CloseIcon onClick={closeModal} />
      </styles.Header>
      <styles.Description>{title}</styles.Description>
    </ModalWrapper>
  );
};
