import styled from "styled-components";

import { hoverTransition } from "shared/theme/styles";

const MenuWrapper = styled.div`
  line-height: 0;
  position: relative;

  span {
    padding: 0.625rem;
    border-radius: 0.5rem;
    transition: ${hoverTransition};

    svg {
      path {
        transition: ${hoverTransition};
        fill: ${({ theme }) => theme.colors.greyOpacity};
      }
    }

    &:hover {
      background-color: ${({ theme }) => theme.colors.grey};

      svg {
        path {
          fill: ${({ theme }) => theme.colors.blue};
        }
      }
    }
  }
`;

const Menu = styled.div`
  right: 0;
  bottom: 100%;
  padding: 0.75rem;
  position: absolute;
  border-radius: 1.25rem;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.buttonGrey};

  button {
    max-width: 100%;
    min-width: 12.5rem;
  }
`;

export default {
  Menu,
  MenuWrapper,
};
