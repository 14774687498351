import styled from "styled-components";

const Title = styled.div`
  font-weight: 600;
  font-size: 1.25rem;
  text-align: center;
  margin-top: 1.8125rem;
  line-height: 1.8125rem;
  color: ${({ theme }) => theme.colors.blue};
`;

const ButtonsWrapper = styled.div`
  bottom: 0;
  z-index: 9;
  width: 100%;
  position: sticky;
  padding-top: 4.5rem;
  background: ${({ theme }) => theme.colors.white};

  button {
    &:last-child {
      min-width: 8.5rem;
    }
  }
`;

const AccountId = styled.div`
  display: flex;
  gap: 0.875rem;
  font-size: 1rem;
  font-weight: 500;
  margin-top: 2rem;
  padding: 0.625rem;
  align-items: center;
  border-radius: 0.5rem;
  color: ${({ theme }) => theme.colors.blue};
  background-color: ${({ theme }) => theme.colors.buttonGrey};
`;

const BalancesContainer = styled.div`
  margin-top: 1.25rem;
`;

const BalancesTitle = styled.div`
  font-weight: 600;
  font-size: 0.825rem;
  margin-bottom: 0.75rem;
`;

const BalanceItem = styled.div`
  display: flex;
  font-weight: 500;
  font-size: 0.825rem;
  align-items: center;
  margin-bottom: 0.5rem;
  justify-content: space-between;
`;

export default {
  Title,
  ButtonsWrapper,
  AccountId,
  BalancesContainer,
  BalancesTitle,
  BalanceItem,
};
