import { createSelector } from "@reduxjs/toolkit";

import { BALANCE_PRECISION, EMPTY_STRING, ZERO, ZERO_STRING } from "shared/constants";
import { IBalance } from "shared/interfaces";
import { formatUnits, formatUnitsWithSpaces } from "shared/utils/calculations";
import { RootState } from "store";
import { selectBalances } from "store/slices/user";

export const selectBalance = createSelector(
  [selectBalances, (_: RootState, id: string) => id],
  (balances, id): IBalance | null => {
    const balance = balances[id] || null;

    return balance;
  }
);

export const selectBalanceData = createSelector([selectBalances, (_: RootState, id: string) => id], (balances, id) => {
  const balanceData = balances[id] || null;

  if (!balanceData) return { balance: ZERO_STRING, symbol: EMPTY_STRING, icon: EMPTY_STRING, decimals: ZERO };

  return {
    balance: formatUnits(balanceData.balance, balanceData.decimal, BALANCE_PRECISION),
    balanceUI: formatUnitsWithSpaces(balanceData.balance, balanceData.decimal, BALANCE_PRECISION),
    symbol: balanceData.symbol,
    icon: balanceData.icon,
    decimals: balanceData.decimal,
  };
});
