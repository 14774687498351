import { Fragment } from "react";
import { v4 as uuid } from "uuid";

import { ReactComponent as MenuIcon } from "assets/images/icons/menu-alt.svg";
import externalStyles from "shared/components/BalancesTimerBlock/styles";
import timerStyles from "shared/components/CountdownTimer/styles";

import styles from "./styles";

export const Preloading = () => {
  return (
    <>
      <styles.PseudoTimerWrapper>
        <styles.PseudoTimerTitle />
        <timerStyles.CountdownTimerWrapper>
          {[...Array(3)].map((_, index) => (
            <Fragment key={index}>
              <timerStyles.TimerItem>
                <div />
                <styles.PseudoTimerSubtitle />
              </timerStyles.TimerItem>
              <timerStyles.Separator>:</timerStyles.Separator>
            </Fragment>
          ))}
          <timerStyles.TimerItem>
            <div />
            <styles.PseudoTimerSubtitle />
          </timerStyles.TimerItem>
        </timerStyles.CountdownTimerWrapper>
      </styles.PseudoTimerWrapper>
      <externalStyles.BalancesBlockWrapper>
        {[...Array(3)].map(() => (
          <styles.AssetItemWrapper key={uuid()}>
            <styles.PseudoIcon />
            <styles.ButtonsContainer>
              <styles.PseudoButton />
              <MenuIcon />
            </styles.ButtonsContainer>
          </styles.AssetItemWrapper>
        ))}
        <styles.AssetItemWrapper>
          <styles.Column>
            <styles.PseudoIconSquare />
            <div>
              <styles.PseudoTitle />
              <styles.PseudoDescription />
              <styles.PseudoDescription />
              <styles.PseudoButtonGrey />
            </div>
          </styles.Column>
          <styles.Column gapInRem="2.75" spaceBetween>
            <div>
              <styles.PseudoDescription widthInRem="4.5" />
              <styles.PseudoTitle widthInRem="5" />
            </div>
            <div>
              {[...Array(3)].map(() => (
                <styles.PseudoIconText key={uuid()}>
                  <styles.PseudoIconMini />
                  <styles.PseudoTitle widthInRem="4.5" />
                </styles.PseudoIconText>
              ))}
            </div>
          </styles.Column>
        </styles.AssetItemWrapper>
      </externalStyles.BalancesBlockWrapper>
    </>
  );
};
