import styled from "styled-components";

const Description = styled.div`
  color: ${({ theme }) => theme.colors.black};
  margin-bottom: 1rem;
`;

const Header = styled.div`
  z-index: 9;
  width: 100%;
  display: flex;
  align-items: "center";
  background: ${({ theme }) => theme.colors.white};
  justify-content: flex-end;
  ${({ theme }) => theme.mediaWidth("upToSmall", `padding: 0 0.5rem;`)}
  svg {
    cursor: pointer;
  }
`;

export default {
  Description,
  Header,
};
