import { Buffer } from "buffer";

import { providers } from "near-api-js";
import { AccountView, CodeResult } from "near-api-js/lib/providers/provider";

import { IRPCProviderService, RPCProviderMethods, ViewArgs, ViewFunction } from "./interfaces";

const FINALITY_FINAL = "final";
const BASE_64 = "base64";
export default class RPCProviderService implements IRPCProviderService {
  private provider?: providers.JsonRpcProvider;

  constructor(provider?: providers.JsonRpcProvider) {
    this.provider = provider;
  }

  async viewFunction<T extends keyof ViewFunction>(method: T, accountId: string, args: ViewArgs = {}) {
    try {
      if (!this.provider) {
        console.warn("No Provider selected");
        return undefined;
      }
      const response = await this.provider.query<CodeResult>({
        request_type: RPCProviderMethods.CALL_FUNCTION,
        account_id: accountId,
        method_name: method,
        args_base64: Buffer.from(JSON.stringify(args)).toString(BASE_64),
        finality: FINALITY_FINAL,
      });
      const result: ViewFunction[typeof method] = JSON.parse(Buffer.from(response.result).toString());
      return result;
    } catch (error) {
      console.error(`${error} \n\n while calling method ${method} on contract ${accountId}`);
      return undefined;
    }
  }

  async viewAccount(accountId: string) {
    try {
      if (!this.provider) {
        console.warn("No Provider selected");
        return undefined;
      }
      return await this.provider.query<AccountView>({
        request_type: RPCProviderMethods.VIEW_ACCOUNT,
        finality: FINALITY_FINAL,
        account_id: accountId,
      });
    } catch (error) {
      console.error(`${error} \n\n account_id: ${accountId}`);
      return undefined;
    }
  }
}
export * from "./interfaces";
