import { configureStore } from "@reduxjs/toolkit";

import { modalsReducer } from "./slices/modals";
import { preStakingReducer } from "./slices/prestakingData";
import { pricesReducer } from "./slices/prices";
import { rewardAccountDataReducer } from "./slices/rewardAccountData";
import { tokensReducer } from "./slices/tokens";
import { userReducer } from "./slices/user";

const store = configureStore({
  reducer: {
    user: userReducer,
    modals: modalsReducer,
    preStaking: preStakingReducer,
    tokens: tokensReducer,
    prices: pricesReducer,
    rewardAccountData: rewardAccountDataReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
