import Big from "big.js";

import { MILLION, TWO, ZERO_STRING } from "shared/constants";
import { removeTrailingZeros } from "shared/utils";

import { BigNumber } from "./calculations";

export const getUserFriendlyAmount = (amount: number | Big | string, precision = TWO) => {
  try {
    const convertedAmount = typeof amount === "string" || typeof amount === "number" ? BigNumber(amount) : amount;

    if (convertedAmount.eq(0)) return ZERO_STRING;

    if (convertedAmount.lte(0.01)) return ">0.01";

    if (convertedAmount.gte(MILLION)) {
      return Math.ceil(convertedAmount.toNumber()).toLocaleString().replace(/,/g, " ");
    }

    return removeTrailingZeros(convertedAmount.toFixed(precision));
  } catch (error) {
    return ZERO_STRING;
  }
};
