import { MILLION } from "shared/constants";

export const getTimeoutValue = (startDate: number, endDate: number) => {
  const start = startDate / MILLION;
  const end = endDate / MILLION;
  const currentDateTime = new Date().getTime();

  if (start > currentDateTime) {
    return start;
  } else if (start < currentDateTime && end > currentDateTime) {
    return end;
  } else {
    return 0;
  }
};
