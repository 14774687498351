import { ContractInfoOutput } from "services/contracts/PreStakingContract/interfaces";
import { IContractInfo } from "shared/interfaces/prestaking.interfaces";
import { checkIsLockStartedAndEnded } from "shared/utils/checkIsLockStartedAndEnded";
import { initialContractInfo } from "store/slices/prestakingData";

export function formatPreStakingContractInfo(contractInfo: ContractInfoOutput | undefined): IContractInfo {
  if (!contractInfo) {
    return initialContractInfo;
  }

  const { isLockStarted, isLockEnded } = checkIsLockStartedAndEnded(contractInfo.start_date, contractInfo.end_date);

  return {
    ownerId: contractInfo.owner_id,
    startDate: contractInfo.start_date,
    endDate: contractInfo.end_date,
    usersCount: contractInfo.users_count,
    isLockStarted: isLockStarted,
    isLockEnded: isLockEnded,
    whitelistedTokens: contractInfo.whitelisted_tokens,
  };
}
