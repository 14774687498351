import { MILLION } from "shared/constants";

export function checkIsLockStartedAndEnded(startDate: number, endDate: number) {
  const currentDateNano = Date.now() * MILLION;

  if (currentDateNano < startDate) {
    return {
      isLockStarted: false,
      isLockEnded: false,
    };
  } else if (currentDateNano >= startDate && currentDateNano < endDate) {
    return {
      isLockStarted: true,
      isLockEnded: false,
    };
  } else {
    return {
      isLockStarted: true,
      isLockEnded: true,
    };
  }
}
