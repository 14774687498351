import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";

import { HomePage } from "pages/HomePage";

import { APP_ROUTES } from "./appRoutes";

export const AppRoutes: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path={APP_ROUTES.HOME} element={<HomePage />} />
        <Route path={APP_ROUTES.DEFAULT} element={<Navigate replace to={APP_ROUTES.HOME} />} />
      </Routes>
    </Router>
  );
};
