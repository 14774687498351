import { createSelector } from "@reduxjs/toolkit";

import { selectPreStakingLoading } from "store/slices/prestakingData";
import { selectRewardAccountDataLoading } from "store/slices/rewardAccountData";
import { selectTokensLoading } from "store/slices/tokens";
import { selectUserLoading } from "store/slices/user";

export const selectGlobalLoading = createSelector(
  [selectPreStakingLoading, selectUserLoading, selectTokensLoading, selectRewardAccountDataLoading],
  (prestakingLoading, userLoading, tokensLoading, rewardAccountDataLoading) =>
    prestakingLoading || userLoading || tokensLoading || rewardAccountDataLoading
);
