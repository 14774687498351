import { ReactComponent as LogoHPS } from "assets/images/logo/hps-logo.svg";

import { ILogoProps } from "./interface";
import styles from "./styles";

export const Logo: React.FC<ILogoProps> = ({ width }: ILogoProps) => {
  return (
    <styles.LogoWrapper width={width}>
      <LogoHPS />
    </styles.LogoWrapper>
  );
};
