import { t } from "i18next";

import { ReactComponent as NearIcon } from "assets/images/icons/near-icon.svg";
import { useWalletSelector } from "providers/WalletProvider";
import { ButtonsGroup } from "shared/components/Buttons/ButtonsGroup";
import { EButtonColors } from "shared/components/Buttons/interfaces";
import { ModalHeader } from "shared/components/modals/components/ModalHeader";
import ModalWrapper from "shared/components/modals/ModalWrapper";
import { Translate } from "shared/components/Translate";
import { FOUR, NEAR_TOKEN_ID, TOKEN_CONTRACT_CHICA, TOKEN_CONTRACT_HAPI, TOKEN_CONTRACT_JUMBO } from "shared/constants";
import { useAppSelector } from "shared/hooks/redux/useAppSelector";
import { IModalProps } from "shared/interfaces";
import { formatUnits } from "shared/utils/calculations";
import { cropString } from "shared/utils/cropString";
import { selectAccountId, selectBalances } from "store/slices/user";

import styles from "./styles";

export const AccountModal: React.FC<IModalProps> = ({ closeModal }: IModalProps) => {
  const { signOut } = useWalletSelector();
  const accountId = useAppSelector(selectAccountId);
  const userBalances = useAppSelector(selectBalances);

  const tokenContracts = [TOKEN_CONTRACT_HAPI, TOKEN_CONTRACT_CHICA, TOKEN_CONTRACT_JUMBO, NEAR_TOKEN_ID];

  const userTokensData = Object.values(userBalances).filter((userTokenData) =>
    tokenContracts.includes(userTokenData.address)
  );

  return (
    <ModalWrapper closeModal={closeModal}>
      <ModalHeader />
      <styles.Title>
        <Translate value="modals.account.title" />
      </styles.Title>
      <styles.AccountId>
        <NearIcon />
        {cropString(accountId)}
      </styles.AccountId>
      {!!userTokensData.length && (
        <styles.BalancesContainer>
          <styles.BalancesTitle>
            <Translate value="modals.account.balances" />
          </styles.BalancesTitle>
          {userTokensData.map((userTokenData) => (
            <styles.BalanceItem key={userTokenData.address}>
              <div>{userTokenData.symbol}</div>
              <div>{formatUnits(userTokenData.balance, userTokenData.decimal, FOUR)}</div>
            </styles.BalanceItem>
          ))}
        </styles.BalancesContainer>
      )}
      <styles.ButtonsWrapper>
        <ButtonsGroup
          cancelButtonHandler={closeModal}
          rightButton={{
            color: EButtonColors.RED,
            name: t("actions.disconnect"),
            handler: () => {
              closeModal();
              signOut();
            },
          }}
        />
      </styles.ButtonsWrapper>
    </ModalWrapper>
  );
};
