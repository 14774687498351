export interface IPreStakingContract {
  getJoinFee(): Promise<string>;
  getUser({ userId }: { userId: string }): Promise<{ [key: string]: string }>;
  getContractInfo(): Promise<ContractInfoOutput>;
}

export enum PreStakingViewMethods {
  getJoinFee = "get_join_fee",
  getUser = "get_user",
  getContractInfo = "get_contract_info",
}

export enum PreStakingChangeMethods {
  JOIN = "join", // (&mut self)
  CLAIM = "claim", // (&mut self)
  UNLOCK_FUNDS = "unlock_funds", // (&mut self, token_id: TokenId)
}

export interface ContractMetadataOutput {
  version: string;
  user_id: string;
}

export interface ContractInfoOutput {
  owner_id: string;
  start_date: number;
  end_date: number;
  users_count: number;
  whitelisted_tokens: { [key: string]: string };
}
