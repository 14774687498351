import styled from "styled-components";

export const H1 = styled.h1<{ isCentered?: boolean }>`
  font-size: 2rem;
  font-weight: 700;
  line-height: 140%;
  margin-bottom: 0.5rem;
  color: ${({ theme }) => theme.colors.blue};
  text-align: ${({ isCentered }) => (isCentered ? "center" : "left")};
  ${({ theme }) => theme.mediaWidth("upToSmall", `white-space: pre-line;`)}
`;

export const H3 = styled.h3<{ isCentered?: boolean }>`
  margin: 0;
  opacity: 0.8;
  font-weight: 500;
  line-height: 160%;
  font-size: 1.25rem;
  white-space: pre-line;
  color: ${({ theme }) => theme.colors.blue};
  text-align: ${({ isCentered }) => (isCentered ? "center" : "left")};
  ${({ theme }) => theme.mediaWidth("upToSmall", `white-space: unset;`)}
`;

const H2Wrapper = styled.h2<{ isCentered?: boolean; withIcon?: boolean }>`
  display: flex;
  margin-top: 0;
  font-size: 1.5rem;
  line-height: 140%;
  align-items: center;
  color: ${({ theme }) => theme.colors.blue};
  font-weight: ${({ withIcon }) => (withIcon ? "600" : "700")};
  margin-top: ${({ withIcon }) => (withIcon ? "0.5rem" : "0")};
  margin-bottom: ${({ withIcon }) => (withIcon ? "1.5rem" : "3rem")};
  text-align: ${({ isCentered }) => (isCentered ? "center" : "left")};
  justify-content: ${({ isCentered }) => (isCentered ? "center" : "flex-start")};

  svg {
    width: 2.25rem;
    height: 2.25rem;
    margin-right: 0.5rem;
  }
`;

export default {
  H2Wrapper,
};
