import FungibleTokenContract from "services/contracts/FungibleToken";
import { IRPCProviderService } from "services/RPCProviderService";
import {
  TOKEN_CONTRACT_CHICA,
  TOKEN_CONTRACT_HAPI,
  TOKEN_CONTRACT_JUMBO,
  TOKEN_TEST_1,
  TOKEN_TEST_2,
  TOKEN_TEST_3,
  ZERO_STRING,
} from "shared/constants";
import { IBalance } from "shared/interfaces";
import theme from "shared/theme";
import { isNotNullOrUndefined, toMap } from "shared/utils";

export async function retrieveTokensMetadata(
  tokenAddresses: string[],
  provider: IRPCProviderService,
  accountId: string
): Promise<{ [key: string]: FungibleTokenContract }> {
  const tokensMetadata: (FungibleTokenContract | null)[] = await Promise.all(
    tokenAddresses.map(async (address: string) => {
      const ftTokenContract: FungibleTokenContract = new FungibleTokenContract({
        provider,
        contractId: address,
        accountId,
      });

      const metadata = await ftTokenContract.getMetadata();

      if (!metadata) return null;

      return ftTokenContract;
    })
  );

  const tokensMetadataFiltered = tokensMetadata.filter(isNotNullOrUndefined);

  return toMap(tokensMetadataFiltered, "contractId");
}

export async function retrieveBalances(
  tokens: { [key: string]: FungibleTokenContract },
  accountId: string
): Promise<{ [key: string]: IBalance }> {
  const balancesArray: IBalance[] = await Promise.all(
    Object.values(tokens).map(async (tokenContract) => {
      const balance: string = (await tokenContract.getBalanceOf({ accountId })) || ZERO_STRING;

      return {
        address: tokenContract.contractId,
        balance,
        decimal: tokenContract.metadata.decimals,
        symbol: tokenContract.metadata.symbol,
        icon: tokenContract.metadata.icon,
      };
    })
  );

  return toMap(balancesArray, "address");
}

export const getToken = (id: string, tokens: { [key: string]: FungibleTokenContract }): FungibleTokenContract | null =>
  tokens[id] || null;

export function getTokenColor(tokenId: string) {
  switch (tokenId) {
    case TOKEN_CONTRACT_HAPI:
    case TOKEN_TEST_1:
      return { circle: theme.colors.tokenHapiColor, bg: theme.colors.tokenHapiColor };
    case TOKEN_CONTRACT_CHICA:
    case TOKEN_TEST_2:
      return { circle: theme.colors.tokenChicaColor, bg: theme.colors.tokenJumboChicaBgColor };
    case TOKEN_CONTRACT_JUMBO:
    case TOKEN_TEST_3:
      return { circle: theme.colors.tokenJumboColor, bg: theme.colors.tokenJumboChicaBgColor };
    default:
      return { circle: theme.colors.greyDark, bg: theme.colors.greyDark };
  }
}
