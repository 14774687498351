import buffer from "buffer";

import { NetworkId, setupWalletSelector, Transaction } from "@near-wallet-selector/core";
import type { WalletSelector, AccountState } from "@near-wallet-selector/core";
import { setupMyNearWallet } from "@near-wallet-selector/my-near-wallet";
import { setupSender } from "@near-wallet-selector/sender";
import { providers } from "near-api-js";
import React, { PropsWithChildren, useCallback, useContext, useEffect, useState } from "react";
import { map, distinctUntilChanged } from "rxjs";

import { networkId, myNearWalletUrl, contractId, nodeUrl } from "services/config";
import { parseTransactions } from "services/receiptsService";
import RPCProviderService, { IRPCProviderService } from "services/RPCProviderService";
import { ZERO_STRING } from "shared/constants";
import { convertGas, setupSenderWallet } from "shared/helpers/wallet";
import { useAppDispatch } from "shared/hooks/redux/useAppDispatch";
import { useAppSelector } from "shared/hooks/redux/useAppSelector";
import { ITransaction } from "shared/interfaces";
import { EWalletName, EWalletType } from "shared/interfaces/wallet.interfaces";
import { selectAccountId, setAccountId, setUserLoading } from "store/slices/user";

import { WalletSelectorContextValue } from "./interface";

window.Buffer = window.Buffer || buffer.Buffer;

declare global {
  interface Window {
    selector: WalletSelector;
  }
}

const WalletSelectorContext = React.createContext<WalletSelectorContextValue>({} as WalletSelectorContextValue);

const ACCOUNT_ID = "accountId";

export const WalletSelectorContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [selector, setSelector] = useState<WalletSelector | null>(null);
  const [RPCProvider, setRPCProvider] = useState<IRPCProviderService>(new RPCProviderService());

  const dispatch = useAppDispatch();
  const accountId = useAppSelector(selectAccountId);

  const syncAccountState = (currentAccountId: string | null, newAccounts: Array<AccountState>) => {
    if (!newAccounts.length) {
      localStorage.removeItem(ACCOUNT_ID);
      dispatch(setAccountId(""));
      dispatch(setUserLoading(false));
      return;
    }
    const validAccountId = currentAccountId && newAccounts.some((x) => x.accountId === currentAccountId);
    const newAccountId = validAccountId ? currentAccountId : newAccounts[0].accountId;
    localStorage.setItem(ACCOUNT_ID, newAccountId);
    dispatch(setAccountId(newAccountId));
    dispatch(setUserLoading(false));
  };

  const init = useCallback(async () => {
    const selectorInstance = await setupWalletSelector({
      network: networkId as NetworkId,
      debug: true,
      modules: [
        setupMyNearWallet({
          walletUrl: myNearWalletUrl,
        }),
        setupSender(),
      ],
    });
    const state = selectorInstance.store.getState();

    syncAccountState(localStorage.getItem(ACCOUNT_ID), state.accounts);
    window.selector = selectorInstance;

    const provider = new providers.JsonRpcProvider({ url: nodeUrl });
    const providerService = new RPCProviderService(provider);
    setRPCProvider(providerService);

    setSelector(selectorInstance);
  }, []);

  useEffect(() => {
    init().catch((err) => {
      console.error(err);
    });
  }, [init]);

  useEffect(() => {
    if (!selector) {
      return;
    }

    const subscription = selector.store.observable
      .pipe(
        map((state) => {
          const result = state.accounts;
          return result;
        }),
        distinctUntilChanged()
      )
      .subscribe((nextAccounts) => {
        syncAccountState(accountId, nextAccounts);
      });

    return () => subscription.unsubscribe();
  }, [selector, accountId]);

  const isSignedIn = useCallback(() => (selector && selector.isSignedIn()) || false, [selector]);

  const requestSignTransactions = useCallback(
    async (transactions: ITransaction[]) => {
      if (!selector) return console.log("No wallet selected");

      const nearTransactions: Transaction[] = transactions.map((transaction: ITransaction) => ({
        signerId: accountId,
        receiverId: transaction.receiverId,
        actions: transaction.functionCalls.map((fc) => ({
          type: "FunctionCall",
          params: {
            methodName: fc.methodName,
            args: fc.args || {},
            gas: convertGas(fc.gas),
            deposit: fc.amount || ZERO_STRING,
          },
        })),
      }));

      const walletInstance = await selector.wallet();
      const result = await walletInstance.signAndSendTransactions({
        transactions: nearTransactions,
      });
      if (result) parseTransactions(result, accountId);
      return result;
    },
    [selector, accountId]
  );

  const signInWithWallet = useCallback(
    async (walletName: EWalletName) => {
      try {
        if (!selector) return;
        const wallet = await selector.wallet(walletName);

        switch (wallet.type) {
          case EWalletType.BROWSER:
            await wallet.signIn({ contractId });
            break;
          case EWalletType.INJECTED:
            await wallet.signIn({ contractId });
            break;
        }
      } catch (error) {
        if (walletName === EWalletName.SENDER) await setupSenderWallet();
        console.error(error);
      }
    },
    [selector]
  );

  const signOut = useCallback(async () => {
    try {
      if (!selector) return;
      const wallet = await selector.wallet();
      await wallet.signOut();
    } catch (error) {
      console.error(error);
    }
  }, [selector, accountId]);

  if (!selector) {
    return null;
  }

  return (
    <WalletSelectorContext.Provider
      value={{
        RPCProvider,
        signInWithWallet,
        signOut,
        isSignedIn,
        requestSignTransactions,
      }}
    >
      {children}
    </WalletSelectorContext.Provider>
  );
};

export function useWalletSelector() {
  return useContext(WalletSelectorContext);
}
