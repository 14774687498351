import { t } from "i18next";

import { Translate } from "shared/components/Translate";
import { CONNECT_WALLET_MODAL_WIDTH_IN_REM, EDimensions } from "shared/constants";
import useWindowDimensions from "shared/hooks/useWindowDimensions";
import { IModalProps } from "shared/interfaces";

import { WalletButtons } from "./components/WalletButtons";
import styles from "./styles";
import { ModalHeader } from "../components/ModalHeader";
import ModalWrapper from "../ModalWrapper";

export const ConnectWalletModal: React.FC<IModalProps> = ({ closeModal }: IModalProps) => {
  const dimensions = useWindowDimensions();
  const isMobile = dimensions === EDimensions.SMALL;

  return (
    <ModalWrapper closeModal={closeModal} widthInRem={CONNECT_WALLET_MODAL_WIDTH_IN_REM}>
      <ModalHeader title={t("modals.connectWallet.title")} isTitleCentered showTitle={!isMobile} />
      <styles.TitleDescriptionWrapper>
        {isMobile && (
          <styles.Title>
            <Translate value="modals.connectWallet.title" />
          </styles.Title>
        )}
        <styles.Description>
          <Translate value="modals.connectWallet.description" />
        </styles.Description>
      </styles.TitleDescriptionWrapper>
      <WalletButtons />
    </ModalWrapper>
  );
};
