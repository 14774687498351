export const colors = {
  red: "#F0414C",
  blue: "#1C1C39",
  grey: "#E1E1E5",
  black: "#000000",
  white: "#FFFFFF",
  whiteRGB: "255, 255, 255",
  green: "#80E45C",
  violet: "#B4BADA",
  greyAlt: "#E0E0E4",
  greyDark: "#494961",
  greyLight: "#777788",
  greyMedium: "#ADADB8",
  greyOpacity: "#D4D4D9",
  background: "#C9C0EF",
  blueLight: "#32324C",
  blueDarken: "#3F3D5D",
  greyDoughnut: "#5B5B70",
  greyBackground: "#F3F3F5",
  greyBackgroundDarken: "#F6F6F7",
  greyBorder: "#EDEDEF",

  buttonBlue: "#1C1C39",
  buttonBlueHover: "#30304D",
  buttonBlueActive: "#444461",
  buttonBlueDisabled: "#D2D2D7",

  buttonGrey: "#E9E9EC",
  buttonGreyHover: "#D2D2D7",
  buttonGreyActive: "#BBBBC4",
  buttonGreyDisabled: "#F8F8F9",

  buttonGreyOpacity: "#B8B0DE",
  buttonGreyOpacityHover: "#D2D2D7",
  buttonGreyOpacityActive: "#BBBBC4",
  buttonGreyOpacityDisabled: "#F8F8F9",

  buttonRed: "#F0414C",
  buttonRedHover: "#FF5560",
  buttonRedActive: "#FF6974",
  buttonRedDisabled: "#F0414C",

  tokenHapiColor: "#FDEE2D",
  tokenChicaColor: "#FF7E06",
  tokenJumboColor: "#FE2C55",
  tokenJumboChicaBgColor: "#131313",

  iconHovered: "#35354D",
};
