import { ECornerPosition } from "./enum";

export const getSimCornerStyles = (position: ECornerPosition) => {
  switch (position) {
    case ECornerPosition.TOP_LEFT:
      return `
        top: 1.5rem; 
        left: 1.5rem; 
        transform: rotate(0);
        `;
    case ECornerPosition.TOP_RIGHT:
      return `
          top: 1.5rem; 
        right: 1.5rem; 
        transform: rotate(90deg);
        `;
    case ECornerPosition.BOTTOM_RIGHT:
      return `
          right: 1.5rem; 
          bottom: 1.5rem; 
          transform: rotate(180deg);
      `;
    default:
      return "top: 1.5rem; left: 1.5rem; transform: rotate(0);";
  }
};
