import { IConfig } from "../config";

export default {
  networkId: "testnet",
  nodeUrl: "https://rpc.testnet.near.org",
  walletUrl: "https://wallet.testnet.near.org",
  helperUrl: "https://testnet-api.kitwallet.app",
  explorerUrl: "https://explorer.testnet.near.org",
  contractId: "prestake.sergei24.testnet",
  rewardContractId: "prestaking-bank.tohapi.testnet",
  wNearAddress: "wrap.testnet",
  usn: "usdn.testnet",
  myNearWalletUrl: "https://testnet.mynearwallet.com/",
  refFinanceIndexerUrl: "https://testnet-indexer.ref-finance.com",
  jumboExchangeIndexerUrl: "https://jumbo-ps.stage.hapi.farm",
  walletDomain: ".testnet",
  senderWalletUrl: "https://sender.org/?origin=ref",
  lockupContractId: "factory-2.rkonoval.testnet",
} as IConfig;
