import { createSelector } from "@reduxjs/toolkit";

import { selectPreStaking } from "store/slices/prestakingData";

export const selectPrestakingData = createSelector([selectPreStaking], (preStaking) => ({
  userStakingBalances: preStaking.userData,
  isLockStarted: preStaking.contractInfo.isLockStarted,
  isLockEnded: preStaking.contractInfo.isLockEnded,
  ownerId: preStaking.contractInfo.ownerId,
  usersCount: preStaking.contractInfo.usersCount,
  startDate: preStaking.contractInfo.startDate,
  endDate: preStaking.contractInfo.endDate,
  whitelistedTokens: preStaking.contractInfo.whitelistedTokens,
}));
