import RPCProviderService from "services/RPCProviderService";
import { TRANSACTION_GAS_PRICE, DEPLOY_CONTRACT_FEE } from "shared/constants";
import { ITransaction } from "shared/interfaces";

import { FactoryChangeMethods, FactoryMethods, IFactoryContract } from "./interfaces";

export default class FactoryContract implements IFactoryContract {
  readonly contractId: string;

  private provider: RPCProviderService;

  public constructor({ provider, contractId }: { provider: RPCProviderService; contractId: string }) {
    this.contractId = contractId;
    this.provider = provider;
  }

  async getLockupId({ accountId }: { accountId: string }): Promise<string | undefined> {
    if (!accountId) return;

    return this.provider.viewFunction(FactoryMethods.getLockupContractId, this.contractId, { account_id: accountId });
  }

  public static createDeployLockupContractTransaction(
    factoryAccountId: string,
    lockupOwnerAccountId: string
  ): ITransaction {
    return {
      receiverId: factoryAccountId,
      functionCalls: [
        {
          methodName: FactoryChangeMethods.deployLockup,
          amount: DEPLOY_CONTRACT_FEE,
          args: {
            user_id: lockupOwnerAccountId,
          },
          gas: TRANSACTION_GAS_PRICE,
        },
      ],
    };
  }
}
