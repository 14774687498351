import { motion } from "framer-motion";
import styled from "styled-components";

import { ReactComponent as Corner } from "assets/images/sim-components/modal-corner.svg";

const BackgroundLayout = styled(motion.div)`
  top: 0;
  left: 0;
  width: 100%;
  z-index: 199;
  height: 100%;
  display: flex;
  overflow: auto;
  position: fixed;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(8px);
`;

const Modal = styled(motion.div)<{ $widthInRem: string; $clearDefaultStyles?: boolean }>`
  z-index: 200;
  overflow: auto;
  padding: 1.5rem;
  max-width: 100%;
  position: relative;
  align-self: center;
  height: fit-content;
  border-radius: 1.5rem;
  width: ${({ $widthInRem }) => `${$widthInRem}rem`};
  background-color: ${({ theme }) => theme.colors.white};
  clip-path: polygon(0 0, 100% 0, 100% 100%, 4rem 100%, 0 calc(100% - 4rem));
  ${({ theme }) => theme.mediaWidth("upToSmall", `padding: 1.5rem 1rem;`)}
`;

const ModalHolder = styled.div`
  overflow: auto;
  max-height: 80vh;
`;

const ModalCorner = styled(Corner)`
  position: absolute;
  left: 0;
  bottom: 0;
`;

const CornerModalContainer = styled.div`
  max-width: 90%;
  position: relative;
`;

export default {
  BackgroundLayout,
  Modal,
  ModalHolder,
  ModalCorner,
  CornerModalContainer,
};
