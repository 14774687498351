import { t } from "i18next";
import React from "react";

import { ReactComponent as WalletIcon } from "assets/images/icons/wallet.svg";
import { ButtonSim } from "shared/components/Buttons/ButtonSim";
import { Logo } from "shared/components/Logo";
import { EDimensions, EMPTY_STRING } from "shared/constants";
import { useAppDispatch } from "shared/hooks/redux/useAppDispatch";
import { useAppSelector } from "shared/hooks/redux/useAppSelector";
import useWindowDimensions from "shared/hooks/useWindowDimensions";
import { cropString } from "shared/utils/cropString";
import { EModals, showModal } from "store/slices/modals";
import { selectAccountId } from "store/slices/user";

import styles from "./styles";

const Header: React.FC = () => {
  const dispatch = useAppDispatch();
  const dimensions = useWindowDimensions();
  const accountId = useAppSelector(selectAccountId);
  const isSmallScreen = dimensions === EDimensions.SMALL;

  const getButtonName = () => (accountId ? cropString(accountId) : t("buttons.connectWallet"));
  const getButtonHandler = () => {
    return accountId
      ? dispatch(showModal({ modal: EModals.ACCOUNT_MODAL, props: {} }))
      : dispatch(showModal({ modal: EModals.CONNECT_WALLET_MODAL, props: {} }));
  };

  return (
    <styles.HeaderWrapper>
      <Logo />
      <ButtonSim
        iconLeft={<WalletIcon />}
        isAuth={Boolean(accountId)}
        name={isSmallScreen ? EMPTY_STRING : getButtonName()}
        handler={() => getButtonHandler()}
      />
    </styles.HeaderWrapper>
  );
};

export default Header;
