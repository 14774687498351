import styled, { css } from "styled-components";

const HeaderWrapper = styled.header`
  z-index: 9;
  width: 100%;
  display: flex;
  padding: 1.5rem;
  align-items: flex-start;
  justify-content: space-between;

  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        top: 0;
        border-radius: 0;
        padding: 1.5rem 1rem;
      `
    )}

  ${({ theme }) =>
    theme.mediaWidth(
      "upToMedium",
      css`
        flex-wrap: wrap;

        nav {
          order: 2;
          width: 100%;
          text-align: center;
          margin-top: 2.8125rem;
        }
      `
    )}
`;

export default {
  HeaderWrapper,
};
