import styled from "styled-components";

const Title = styled.div`
  font-weight: 600;
  font-size: 1.5rem;
  text-align: center;
  margin-top: 1.8125rem;
  line-height: 1.8125rem;
  color: ${({ theme }) => theme.colors.blue};
`;

const Description = styled.div`
  font-weight: 400;
  line-height: 140%;
  text-align: center;
  margin-top: 0.75rem;
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.blueDarken};
`;

const TitleDescriptionWrapper = styled.div`
  margin-bottom: 1.5rem;
`;

const Link = styled.a`
  font-weight: 600;
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.blue};
`;

export default {
  Title,
  Description,
  TitleDescriptionWrapper,
  Link,
};
