import { ToastOptions, Slide } from "react-toastify";

import theme from "shared/theme/index";

export const toastOptions: ToastOptions = {
  position: "top-center",
  transition: Slide,
  hideProgressBar: true,
  closeButton: false,
  style: {
    background: theme.colors.blue,
    boxShadow: `box-shadow: 0px 4px 12px -4px rgba(20, 25, 49, 0.2)`,
    borderRadius: "1.5rem",
    color: theme.colors.white,
    padding: ".5rem",
    width: "100%",
  },
};
