import { ZERO_STRING } from "shared/constants";
import { IRefTokenPrice, ITokenPrice } from "shared/interfaces";

import { fetchSendJson } from "./index";
import { helperUrl, jumboExchangeIndexerUrl, refFinanceIndexerUrl } from "../config";

class FiatValueManager {
  async getJumboPriceData(): Promise<{ [key: string]: ITokenPrice }> {
    const url = `${jumboExchangeIndexerUrl}/token-prices`;
    try {
      const jumboPricesData: ITokenPrice[] = await fetchSendJson({ url });
      return jumboPricesData.reduce(
        (acc: { [key: string]: ITokenPrice }, item: ITokenPrice) => ({
          ...acc,
          [item.id]: item,
        }),
        {}
      );
    } catch (e) {
      console.warn(`Error ${e} while loading prices from ${url}`);
      return {};
    }
  }

  async getRefPriceData(): Promise<{ [key: string]: ITokenPrice }> {
    const url = `${refFinanceIndexerUrl}/list-token-price`;
    try {
      const refPriceData: { [key: string]: IRefTokenPrice } = await fetchSendJson({ url });
      return Object.entries(refPriceData).reduce(
        (acc: { [key: string]: ITokenPrice }, [id, item]: [string, IRefTokenPrice]) => ({
          ...acc,
          [id]: {
            id,
            ...item,
          },
        }),
        {}
      );
    } catch (e) {
      console.warn(`Error ${e} while loading prices from ${url}`);
      return {};
    }
  }

  async getNearPrice(): Promise<string> {
    const url = `${helperUrl}/fiat`;
    try {
      const nearPrice: number = await fetchSendJson({ url }).then((res) => res.near.usd || 0);
      return nearPrice.toString();
    } catch (e) {
      console.warn(`Error ${e} while loading near price from ${url}`);
      return ZERO_STRING;
    }
  }
}

export default new FiatValueManager();
