import { FiatValueManager } from "services/api";
import { wNearAddress } from "services/config";
import { ITokenPrice } from "shared/interfaces";
import { isNotNullOrUndefined, toMap } from "shared/utils";
import { BigNumber } from "shared/utils/calculations";

const WNEAR_INITIAL_DATA: ITokenPrice = {
  id: wNearAddress,
  decimal: 24,
  symbol: "wNEAR",
  price: "0",
};

export async function retrievePrices(tokenAddresses: string[]): Promise<{ [key: string]: ITokenPrice }> {
  const [jumboPrices, refPriceData, nearPrice] = await Promise.all([
    FiatValueManager.getJumboPriceData(),
    FiatValueManager.getRefPriceData(),
    FiatValueManager.getNearPrice(),
  ]);

  const prices = tokenAddresses
    .map((tokenId) => {
      if (tokenId === wNearAddress) return { ...WNEAR_INITIAL_DATA, price: nearPrice };
      const jumboPrice = jumboPrices[tokenId] || null;
      const refPrice = refPriceData[tokenId] || null;
      if (jumboPrice && refPrice) {
        const averagePrice = BigNumber(jumboPrice.price).add(BigNumber(refPrice.price)).div(2).toFixed(2);
        return { ...jumboPrice, price: averagePrice };
      }
      return jumboPrice || refPrice || null;
    })
    .filter(isNotNullOrUndefined);
  return toMap(prices, "id");
}

export const getPrice = (id: string, prices: { [key: string]: ITokenPrice }): ITokenPrice | null => prices[id] || null;
