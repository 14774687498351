import styled from "styled-components";

const TimerWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  margin-bottom: 2.25rem;
  justify-content: center;
`;

const TimerTitle = styled.div`
  width: 100%;
  font-size: 1rem;
  font-weight: 500;
  line-height: 120%;
  margin-bottom: 1rem;
  color: ${({ theme }) => theme.colors.blue};
`;

const BalancesBlockWrapper = styled.div`
  width: 45rem;
  margin: 0 auto;
  max-width: 90%;
  padding: 0.75rem;
  border-radius: 2rem;
  background-color: ${({ theme }) => theme.colors.white};
  ${({ theme }) => theme.mediaWidth("upToSmall", `max-width: 100%;`)}
`;

const AssetItem = styled.div`
  gap: 0.5rem;
  display: grid;
  padding: 0.75rem;
  align-items: center;
  border-radius: 1.25rem;
  margin-bottom: 0.75rem;
  grid-template-columns: 3fr 2fr;
  border: 1px solid ${({ theme }) => theme.colors.greyBorder};
  background-color: ${({ theme }) => theme.colors.greyBackgroundDarken};

  &:last-child {
    margin-bottom: 0;
  }

  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      `
        gap: 1.5rem;
        grid-template-columns: 3fr;
      `
    )}
`;

const RewardItem = styled(AssetItem)`
  align-items: flex-start;
`;

const IconToken = styled.img<{ small?: boolean; color?: string }>`
  border-radius: 50%;
  object-fit: contain;
  background-color: ${({ color }) => color};
  width: ${({ small }) => (small ? "1.5rem" : "2.5rem")};
  height: ${({ small }) => (small ? "1.5rem" : "2.5rem")};
  margin-right: ${({ small }) => (small ? "0.5rem" : "0.75rem")};
`;

const TokenDataWrapper = styled.div`
  display: flex;

  > svg {
    margin-right: 0.75rem;
  }

  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      `
        width: 100%;
      `
    )}
`;

const StakeDataWrapper = styled.div`
  gap: 0.5rem;
  display: flex;

  button {
    margin: 0 0 0 auto;
  }

  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      `
        width: 100%;
      `
    )}
`;

const TokenData = styled.div`
  width: 100%;

  button {
    margin-top: 0.75rem;
  }

  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      `
        width: 100%;
      `
    )}
`;

const TokenName = styled.div`
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.2rem;
  margin-bottom: 0.25rem;
  color: ${({ theme }) => theme.colors.blue};
`;

const RewardName = styled(TokenName)`
  line-height: 120%;
  font-size: 0.875rem;
`;

const TokenDescription = styled.div`
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 0.9rem;
  color: ${({ theme }) => theme.colors.greyLight};
`;

const RewardDescription = styled(TokenDescription)`
  font-weight: 400;
  line-height: 140%;
`;

const StakeData = styled.div`
  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      `
        flex: 1;
      `
    )}
`;

const StakeTitle = styled.div`
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 0.9rem;
  margin-bottom: 0.25rem;
  color: ${({ theme }) => theme.colors.greyLight};
`;

const StakeValue = styled.div`
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.2rem;
  color: ${({ theme }) => theme.colors.blue};
`;

const RewardList = styled.div`
  margin-left: 2rem;
`;

const RewardListItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
`;

const TokenRewardData = styled.div`
  font-weight: 500;
  line-height: 160%;
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.blue};
`;

const ClaimAllWrapper = styled.div`
  margin: 1.5rem 0;
`;

const StakeMenuWrapper = styled.div`
  flex: 1;
  gap: 0.5rem;
  display: flex;
  align-items: center;
`;

export default {
  TimerWrapper,
  TimerTitle,
  BalancesBlockWrapper,
  AssetItem,
  RewardItem,
  IconToken,
  TokenData,
  TokenName,
  RewardName,
  TokenDescription,
  RewardDescription,
  StakeData,
  StakeTitle,
  StakeValue,
  TokenDataWrapper,
  StakeDataWrapper,
  RewardList,
  RewardListItem,
  TokenRewardData,
  ClaimAllWrapper,
  StakeMenuWrapper,
};
