import styled from "styled-components";

import { hoverTransition } from "shared/theme/styles";

const QuestionTogglerWrapper = styled.div<{ toggleActive: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    margin-right: 1.5rem;
    transform: ${({ toggleActive }) => (toggleActive ? "rotate(180deg)" : "rotate(0)")};
    transition: ${hoverTransition};

    svg {
      path {
        fill: ${({ toggleActive, theme }) => toggleActive && theme.colors.blue};
      }
    }
  }
`;

const Question = styled.div`
  font-size: 1rem;
  cursor: pointer;
  font-weight: 700;
  line-height: 160%;
  color: ${({ theme }) => theme.colors.blue};
`;

const Answer = styled.div`
  opacity: 0.8;
  font-size: 1rem;
  margin-top: 1rem;
  font-weight: 500;
  line-height: 160%;
  color: ${({ theme }) => theme.colors.blue};
`;

export default {
  QuestionTogglerWrapper,
  Question,
  Answer,
};
