import theme from "shared/theme";

import { EButtonColors, EButtonSizes, IButtonStatusColors } from "./interfaces";

export function getButtonStatusColors(color: EButtonColors): IButtonStatusColors {
  switch (color) {
    case EButtonColors.RED:
      return {
        default: theme.colors.buttonRed,
        active: theme.colors.buttonRedActive,
        hover: theme.colors.buttonRedHover,
        disabled: theme.colors.buttonRedDisabled,
      };
    case EButtonColors.BLUE:
      return {
        default: theme.colors.buttonBlue,
        active: theme.colors.buttonBlueActive,
        hover: theme.colors.buttonBlueHover,
        disabled: theme.colors.buttonBlueDisabled,
      };
    case EButtonColors.GREY:
      return {
        default: theme.colors.buttonGrey,
        active: theme.colors.buttonGreyActive,
        hover: theme.colors.buttonGreyHover,
        disabled: theme.colors.buttonGreyDisabled,
      };
    case EButtonColors.GREY_OPACITY:
      return {
        default: theme.colors.buttonGreyOpacity,
        active: theme.colors.buttonGreyOpacityActive,
        hover: theme.colors.buttonGreyOpacityHover,
        disabled: theme.colors.buttonGreyOpacityDisabled,
      };
    case EButtonColors.TRANSPARENT:
      return {
        default: theme.colors.buttonBlue,
        active: theme.colors.buttonBlueActive,
        hover: theme.colors.buttonBlueHover,
        disabled: theme.colors.buttonBlueDisabled,
      };
    default:
      return {
        default: theme.colors.buttonBlue,
        active: theme.colors.buttonBlueActive,
        hover: theme.colors.buttonBlueHover,
        disabled: theme.colors.buttonBlueDisabled,
      };
  }
}

export function getButtonHeight(size: EButtonSizes): string {
  switch (size) {
    case EButtonSizes.XS:
      return "1.75rem";
    case EButtonSizes.S:
      return "2rem";
    case EButtonSizes.M:
      return "2.5rem";
    case EButtonSizes.L:
      return "2.75rem";
    case EButtonSizes.XL:
      return "3rem";
    default:
      return "2.5rem";
  }
}
