import { t } from "i18next";
import { providers } from "near-api-js";
import { useEffect } from "react";

import { explorerUrl, nodeUrl } from "services/config";
import { ITranslationKeys } from "services/translation";
import ToastService from "shared/components/Toast";
import { useAppSelector } from "shared/hooks/redux/useAppSelector";
import { isNotNullOrUndefined } from "shared/utils";
import { selectAccountId } from "store/slices/user";

const PROPERTY_NAME = "FunctionCall";
const TRANSACTION_HASHES = "transactionHashes";
const ERROR_CODE = "errorCode";
const ERROR_MESSAGE = "errorMessage";
const SUCCESS_VALUE = "SuccessValue";

const methodsName = {
  confirm: "confirm",
  ftTransferCall: "ft_transfer_call",
  claim: "claim",
  unlockFunds: "unlock_funds",
};

enum TransactionType {
  None = "none",
  FtTransferCall = "ftTransferCall",
  Claim = "claim",
  UnlockFunds = "unlockFunds",
}

enum StatusType {
  None,
  SuccessValue,
  Failure,
}

interface IDetailsTransaction {
  methodName: string;
  href: string;
  status: StatusType;
  type: TransactionType;
  title: ITranslationKeys;
}

const clearHash = (queryParams: URLSearchParams) => {
  const url = new URL(window.location.href);
  if (queryParams.has(TRANSACTION_HASHES)) queryParams.delete(TRANSACTION_HASHES);

  if (queryParams.has(ERROR_CODE) || queryParams.has(ERROR_MESSAGE)) {
    queryParams.delete(ERROR_CODE);
    queryParams.delete(ERROR_MESSAGE);
  }
  window.history.replaceState({}, document.title, url.pathname);
};

const retrieveTypeTransaction = (methodName: string) => {
  switch (methodName) {
    case methodsName.ftTransferCall: {
      return TransactionType.FtTransferCall;
    }
    case methodsName.claim: {
      return TransactionType.Claim;
    }
    case methodsName.unlockFunds: {
      return TransactionType.UnlockFunds;
    }
    default: {
      return TransactionType.None;
    }
  }
};

const detailsTransaction = (transaction: providers.FinalExecutionOutcome): IDetailsTransaction => {
  const methodName = transaction.transaction.actions[0][PROPERTY_NAME].method_name as string;
  const type = retrieveTypeTransaction(methodName);
  const successStatus = Object.prototype.hasOwnProperty.call(transaction.status, SUCCESS_VALUE);
  const hash = transaction.transaction.hash as string;

  return {
    methodName,
    href: `${explorerUrl}/transactions/${hash}`,
    status: successStatus ? StatusType.SuccessValue : StatusType.Failure,
    type,
    title: `toast.${type}` as ITranslationKeys,
  };
};

export function parseTransactions(transactions: providers.FinalExecutionOutcome[], accountId: string) {
  const suitableTransactions = transactions.map((tx) => detailsTransaction(tx)).filter(isNotNullOrUndefined);
  if (!suitableTransactions.length) return;

  const uniqSuitableTransactions = suitableTransactions.reduce((acc: IDetailsTransaction[], transaction) => {
    if (!acc.find((v) => v.type == transaction.type)) acc.push(transaction);
    return acc;
  }, []);

  uniqSuitableTransactions.forEach((transaction) => {
    if (transaction.type === TransactionType.None) return;
    if (transaction.status === StatusType.SuccessValue) {
      ToastService.success({ text: t(transaction.title, { accountId }) });
    } else if (transaction.status === StatusType.Failure) {
      ToastService.error({ text: t(transaction.title), href: transaction.href });
    }
  });
}

export default function useTransactionHash() {
  const accountId = useAppSelector(selectAccountId);
  const url = new URL(window.location.href);
  return useEffect(() => {
    if (accountId) {
      const queryParams = new URLSearchParams(url.search);
      const transactions = queryParams?.get(TRANSACTION_HASHES);
      const errorCode = queryParams?.get(ERROR_CODE);
      const errorMessage = queryParams?.get(ERROR_MESSAGE);
      if (errorCode || errorMessage) ToastService.error({ text: t("toast.error.userRejected") });
      clearHash(queryParams);

      if (transactions) {
        const provider = new providers.JsonRpcProvider({ url: nodeUrl });

        try {
          Promise.all(transactions.split(",").map((txHash) => provider.txStatus(txHash, accountId)))
            .then((res) => parseTransactions(res, accountId))
            .catch((e) => console.warn(`${e} error while parse transactions`));
        } catch (e) {
          console.warn(`${e} error while loading tx`);
        }
      }
    }
  }, [accountId, url.search]);
}
