import { useState } from "react";

import { ReactComponent as DropdownArrow } from "assets/images/icons/arrow-dropdown.svg";
import { SlideDown } from "shared/components/Animations";
import { ButtonIcon } from "shared/components/Buttons/ButtonIcon";

import { IFAQItem } from "./interface";
import styles from "./styles";

const FAQItem: React.FC<IFAQItem> = ({ question, answer }: IFAQItem) => {
  const [showAnswer, setShowAnswer] = useState<boolean>(false);

  return (
    <div>
      <styles.QuestionTogglerWrapper toggleActive={showAnswer}>
        <styles.Question onClick={() => setShowAnswer(!showAnswer)}>{question}</styles.Question>
        <ButtonIcon control={{ icon: <DropdownArrow />, handler: () => setShowAnswer(!showAnswer) }} />
      </styles.QuestionTogglerWrapper>
      <SlideDown show={showAnswer}>
        <styles.Answer>{answer}</styles.Answer>
      </SlideDown>
    </div>
  );
};

export default FAQItem;
