import { t } from "i18next";
import Countdown from "react-countdown";

import { ZERO_STRING } from "shared/constants";

import { ICountdownTimerProps } from "./interface";
import styles from "./styles";

const CountdownTimer: React.FC<ICountdownTimerProps> = ({ timer }: ICountdownTimerProps) => {
  return (
    <Countdown
      date={timer}
      daysInHours={true}
      renderer={({ days, hours, minutes, seconds }) => {
        const daysLabel = days === 1 ? t("countdown.singular.days") : t("countdown.plural.days");
        const hoursLabel = hours === 1 ? t("countdown.singular.hours") : t("countdown.plural.hours");
        const minutesLabel = minutes === 1 ? t("countdown.singular.minutes") : t("countdown.plural.minutes");
        const secondsLabel = seconds === 1 ? t("countdown.singular.seconds") : t("countdown.plural.seconds");

        return (
          <styles.CountdownTimerWrapper>
            <styles.TimerItem>
              <div>{String(days).padStart(2, ZERO_STRING)}</div>
              <div>{daysLabel}</div>
            </styles.TimerItem>
            <styles.Separator>:</styles.Separator>
            <styles.TimerItem>
              <div>{String(hours).padStart(2, ZERO_STRING)}</div>
              <div>{hoursLabel}</div>
            </styles.TimerItem>
            <styles.Separator>:</styles.Separator>
            <styles.TimerItem>
              <div>{String(minutes).padStart(2, ZERO_STRING)}</div>
              <div>{minutesLabel}</div>
            </styles.TimerItem>
            <styles.Separator>:</styles.Separator>
            <styles.TimerItem>
              <div>{String(seconds).padStart(2, ZERO_STRING)}</div>
              <div>{secondsLabel}</div>
            </styles.TimerItem>
          </styles.CountdownTimerWrapper>
        );
      }}
    />
  );
};

export default CountdownTimer;
