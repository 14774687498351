import styled from "styled-components";

export const InputWrapper = styled.input<{ error?: boolean }>`
  width: 100%;
  outline: none;
  padding: 0.5rem;
  font-size: 1rem;
  height: 2.75rem;
  font-weight: 700;
  background: none;
  line-height: 120%;
  font-style: normal;
  border-radius: 0.75rem;
  color: ${({ theme }) => theme.colors.blue};
  border: 1px solid ${({ theme, error }) => (error ? theme.colors.red : theme.colors.greyMedium)};

  ::placeholder {
    color: ${({ theme }) => theme.colors.greyLight};
  }

  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export default {
  InputWrapper,
};
