import i18n from "services/translation";
import { MILLION } from "shared/constants";

export function nanosecToUtcDate(nanoseconds: number) {
  const milliseconds = Math.floor(nanoseconds / MILLION);
  const date = new Date();

  date.setTime(milliseconds);

  const utcDateString = date.toISOString();
  const utcDateParts = utcDateString.split("T");
  const utcDate = utcDateParts[0];
  const utcTimeParts = utcDateParts[1].slice(0, -5).split(":");
  const utcTime = `${utcTimeParts[0]}:${utcTimeParts[1]}`;

  return i18n.t("common.date", { value: `${utcDate} ${utcTime}` });
}
