import styled from "styled-components";

const FAQWrapper = styled.div`
  width: 45rem;
  max-width: 90%;
  margin: 4.5rem auto 0 auto;
  ${({ theme }) => theme.mediaWidth("upToSmall", `max-width: 100%;`)}

  h2 + div {
    border-top: 1px solid ${({ theme }) => theme.colors.greyLight};
  }

  > div {
    padding: 2rem 0;
    border-bottom: 1px solid ${({ theme }) => theme.colors.greyLight};
  }
`;

export default {
  FAQWrapper,
};
