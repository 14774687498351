import { z as zod } from "zod";

import i18n from "services/translation";
import { EMPTY_STRING, ZERO } from "shared/constants";
import { BigNumber } from "shared/utils/calculations";

export const stakingFormValidationSchema = (balance: string) => {
  return zod.object({
    depositAmount: zod
      .string()
      .refine(
        (amount) => BigNumber(amount).gt(ZERO),
        () => ({ message: i18n.t("modals.staking.inputs.amount.errors.stakeAmount") || EMPTY_STRING })
      )
      .refine(
        (amount) => BigNumber(amount).lte(BigNumber(balance)) && !BigNumber(amount).eq(0),
        () => ({ message: i18n.t("modals.staking.inputs.amount.errors.insufficientBalance") || EMPTY_STRING })
      ),
    privacyAgreement: zod.literal(true),
  });
};
