export const MEDIA_WIDTHS = {
  upToSmall: 600,
  upToMedium: 900,
  upToLarge: 1280,
};

export enum EDimensions {
  SMALL = "SMALL",
  MEDIUM = "MEDIUM",
  LARGE = "LARGE",
  EXTRA_LARGE = "EXTRA_LARGE",
  UNKNOWN = "UNKNOWN",
}

export const linkOptions = {
  blank: "_blank",
  noreferrer: "noreferrer",
};

export const partnersLinks = {
  jumbo: "https://jumbo.exchange/",
  lockup: "https://lockup.dev/",
  near: "https://near.org/",
  hapi: "https://hapi.one/",
  pitchtalk: "https://pitchtalk.com/",
  bocachica: "https://bocachica.io/",
};

export const CHART_DOUGHNUT_CUTOUT_DEFAULT = 20;
export const CHART_DOUGHNUT_CUTOUT_MOBILE = 37;

export const TWO = 2;
export const FOUR = 4;
export const ZERO = 0;
export const DAYS_A_YEAR = 365;
export const ONE_HUNDRED = 100;
export const MILLION = 1000000;
export const PRECISION_TWO = 2;
export const ONE_MINUTE = 60000;
export const BALANCE_PRECISION = 3;
export const ONE_DAY = ONE_MINUTE * 60 * 24;

export const FORMAT_DOT = ".";
export const FORMAT_COMMA = ",";

export const ZERO_STRING = "0";
export const EMPTY_STRING = "";
export const FULFILLED = "fulfilled";
export const DEFAULT_MODAL_WIDTH_IN_REM = "20";
export const CONNECT_WALLET_MODAL_WIDTH_IN_REM = "35";

export const LOCKUP_URL = "https://lockup.dev/";

export * from "./wallets";

export const DEPLOY_CONTRACT_FEE = "2100000000000000000000000"; // 2.1
