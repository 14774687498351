import styled from "styled-components";

const InfoBlockWrapper = styled.div`
  width: 45rem;
  max-width: 100%;
  margin: 2.5rem auto 3.8125rem auto;
  ${({ theme }) => theme.mediaWidth("upToMedium", `max-width: 85%;`)}
  ${({ theme }) => theme.mediaWidth("upToSmall", `padding: 0 0.5rem; max-width: 100%;`)}

  button {
    margin: 0 auto;
  }
`;

const TitleSubtitleContainer = styled.div`
  margin-bottom: 2.3125rem;
`;

const ImageContainer = styled.img`
  margin: 0.5rem 0;
`;

export default {
  InfoBlockWrapper,
  TitleSubtitleContainer,
  ImageContainer,
};
