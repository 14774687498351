import { FinalExecutionOutcome } from "@near-wallet-selector/core";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { t } from "i18next";

import { contractId } from "services/config";
import PreStakingContract from "services/contracts/PreStakingContract";
import RPCProviderService from "services/RPCProviderService";
import ToastService from "shared/components/Toast";
import { ITransaction } from "shared/interfaces";
import { RootState } from "store";

import { updateState } from "./updateState";

export const unlockFunds = createAsyncThunk<
  void,
  {
    tokenId: string;
    provider: RPCProviderService;
    requestSignTransactions: (transaction: ITransaction[]) => Promise<void | FinalExecutionOutcome[]>;
  },
  { state: RootState }
>("preStaking/unlockFunds", async ({ tokenId, provider, requestSignTransactions }, { dispatch }) => {
  try {
    const preStakingContract = new PreStakingContract({ provider, contractId });
    const transaction = await preStakingContract.createUnlockFundsTransaction(tokenId);
    if (!transaction) return;

    const result = await requestSignTransactions([transaction]);

    if (result) dispatch(updateState({ provider: provider, contractId }));
  } catch (error) {
    ToastService.error({ text: t("toast.error.unlockFunds") });
    console.error("Error during token stake: ", error);
  }
});
