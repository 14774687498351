import theme from "shared/theme";
import { Sim } from "shared/theme/sim";

import { EButtonColors, EButtonSizes, IButtonProps } from "./interfaces";
import styles from "./styles";

export function ButtonSim({
  name,
  handler,
  iconLeft,
  iconRight,
  fullWidth = false,
  size = EButtonSizes.M,
  color = EButtonColors.BLUE,
  isAuth,
}: IButtonProps) {
  return (
    <>
      <Sim />
      <styles.ButtonSimWrapper
        type="button"
        onClick={handler}
        color={isAuth ? EButtonColors.GREY_OPACITY : color}
        fullWidth={fullWidth}
        size={size}
        textColor={isAuth ? theme.colors.blue : theme.colors.white}
      >
        {iconLeft || null}
        {name}
        {iconRight || null}
      </styles.ButtonSimWrapper>
    </>
  );
}
