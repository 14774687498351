import { useEffect } from "react";

import { useWalletSelector } from "providers/WalletProvider";
import { contractId } from "services/config";
import { BalancesTimerBlock } from "shared/components/BalancesTimerBlock";
import FAQ from "shared/components/FAQ";
import { PartnersBlock } from "shared/components/PartnersBlock";
import { StakingPoolInformation } from "shared/components/StakingPoolInformation";
import { ONE_MINUTE } from "shared/constants";
import { useAppDispatch } from "shared/hooks/redux/useAppDispatch";
import { updateState } from "store/actions/updateState";

import { InfoBlock } from "./components/InfoBlock";
import styles from "./styles";

export const HomePage: React.FC = () => {
  const dispatch = useAppDispatch();
  const { RPCProvider } = useWalletSelector();

  useEffect(() => {
    const timeoutId = setInterval(() => {
      dispatch(updateState({ provider: RPCProvider, contractId }));
    }, ONE_MINUTE);

    return () => {
      clearInterval(timeoutId);
    };
  }, []);

  return (
    <styles.HomeWrapper>
      <InfoBlock />
      <StakingPoolInformation />
      <BalancesTimerBlock />
      <FAQ />
      <PartnersBlock />
    </styles.HomeWrapper>
  );
};
