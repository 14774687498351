import { t } from "i18next";

import { H2 } from "shared/components/Typography";

import FAQItem from "./FAQItem";
import styles from "./styles";

const FAQ: React.FC = () => {
  return (
    <styles.FAQWrapper>
      <H2 text={t("faq.title")} isCentered />
      {Array.from({ length: 10 }, (_, i) => i + 1).map((item) => (
        <FAQItem
          key={t(`faq.list.${item}.question`)}
          question={t(`faq.list.${item}.question`)}
          answer={t(`faq.list.${item}.answer`)}
        />
      ))}
    </styles.FAQWrapper>
  );
};

export default FAQ;
