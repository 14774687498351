import { t } from "i18next";

import { useWalletSelector } from "providers/WalletProvider";
import { wallets } from "shared/constants";
import { useAppDispatch } from "shared/hooks/redux/useAppDispatch";
import { EWalletName } from "shared/interfaces/wallet.interfaces";
import { closeModal } from "store/slices/modals";

import styles from "./styles";

export const WalletButtons: React.FC = () => {
  const dispatch = useAppDispatch();
  const { signInWithWallet } = useWalletSelector();

  function connectWalletHandler(walletName: EWalletName) {
    signInWithWallet(walletName);
    dispatch(closeModal());
  }

  return (
    <styles.WalletButtonsWrapper>
      {wallets.map((wallet) => {
        const Logo = wallet.logo;

        return (
          <button key={wallet.key} onClick={() => connectWalletHandler(wallet.key)}>
            <Logo />
            <span>{t(wallet.name)}</span>
          </button>
        );
      })}
    </styles.WalletButtonsWrapper>
  );
};
