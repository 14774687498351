import { createAsyncThunk } from "@reduxjs/toolkit";

import { rewardContractId } from "services/config";
import { IRPCProviderService } from "services/RPCProviderService";
import { retrieveBalances, retrieveTokensMetadata } from "shared/helpers/tokens";
import { setRewardAccountBalances, setRewardAccountLoading } from "store/slices/rewardAccountData";

import { RootState } from "../index";

export const loadRewardBalances = createAsyncThunk<
  void,
  {
    tokenAddresses: string[];
    provider: IRPCProviderService;
  },
  { state: RootState }
>("users/loadBalances", async ({ tokenAddresses, provider }, { dispatch }) => {
  try {
    const rewardTokens = await retrieveTokensMetadata(tokenAddresses, provider, rewardContractId);
    const rewardTokenBalances = await retrieveBalances(rewardTokens, rewardContractId);

    if (!rewardTokenBalances) return;

    dispatch(setRewardAccountBalances(rewardTokenBalances));
  } catch (e) {
    console.error(`Error: while loading reward balances \n ${e}`);
  } finally {
    dispatch(setRewardAccountLoading(false));
  }
});
